<template>
  <div :class="$t('Typeface.ztAl55')">
  <el-config-provider :locale="locale">
    <router-view v-if="isRouterAlive" />
  </el-config-provider>
</div>
</template>
<script>
import { nextTick, provide, ref, computed } from "vue";
import { ElConfigProvider } from "element-plus";

import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
export default {
  components: {
    ElConfigProvider,
  },
  setup() {
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    let pd = localStorage.getItem("language") == "zh" ? 1 : 2;
    const language = ref(pd==1?"zh-cn":"en");
    const locale = computed(() => (language.value === "zh-cn" ? zhCn : en));
    const toggle = () => {
      language.value = language.value === "zh-cn" ? "en" : "zh-cn";
    };
    provide("toggle", toggle);
    provide("reload", reload);
    return {
      isRouterAlive,
      reload,
      locale,
    };
  },
};
</script>
<style lang="less">
#app {
  font-family: 'Inter-Regular';
}

</style>
