import { createI18n } from "vue-i18n";
const i18n = createI18n({
    legacy: false, // 让 setup 函数可以通过 t 访问
    globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
    locale: localStorage.getItem("language") || "en",
    messages: {
        zh: {
            floor: {
                topTitle1: "即使身处全球",
                topTitle2: "都能获取优质的中文课程",
                topTxt1: "1对1，55分钟直播互动课",
                topTxt2: "配套课程录播功能，永久回看",
                topTxt3: "课后习题练习批改，完整学习闭环",
                topTxt4: "赠送家长会，定期反馈学习效果",
                title1: "关注知行",
                title2: "联系我们",
                text1: "中国总部：",
                text2: "美国公司：",
                text3: "香港公司：",
                class1: "中文课程",
                class2: "中文考试",
                class3: "常见问题",
                class4: "预约试听",
                class5: "考试信息",
                class6: "知行师资",
                class7: "拓展阅读",
                class8: "知行优势",
            },
            Typeface: {
                ztAl35: "ztAl35",
                ztAl55: "ztAl55",
                ztAl75: "ztAl75",
            },
            isLang: "1",
            header: {
                li1: "中文课程",
                li2: "中文考试",
                li3: "知行优势",
                li4: "知行师资",
                li5: "扩展阅读",
                li6: "免费试听",
                li7: "青少年中文课程",
                li8: "HSK标准课程",
                button: "学员中心",
            },
            home: {
                minTit1: "课程体系",
                minTit2: "知行特色",
                minTit3: "客户评价",
                minTit31: "客户评价",
                minTit4: "师资队伍",
                minTit5: "知行理念",
                minTit6: "常见问题",
                minTit61: "常见问题",
                minTit7: "课程大纲",
                minTit8: "课程内容",
                minTit9: "课程特色",
                minTit10: "知行优势",
                minTit11: "多元发展",
                minTit12: "助力成长",
                minTit13: "知行教研",
                minTit14: "知行承诺",
                courseType1: "青少年中文课程",
                courseTypePE31: "青少年",
                courseTypePE32: "青少年中文",
                courseTypePE11: "AP",
                courseTypePE12: "AP中文",
                courseType2: "HSK标准课程",
                courseTypePE21: "HSK",
                courseTypePE22: "HSK中文",
                courseType3: "AP中文课程",
                courseType4: "IB中文课程",
                courseTypePE41: "IB",
                courseTypePE42: "IB中文",
                courseType5: "O-LEVEL中文课程",
                courseTypePE51: "O-Level",
                courseTypePE52: "O-Level中文",
                courseTh1: "课程分级",
                courseTh2: "综合目标",
                courseTh3: "听说读写",
                courseTh4: "字词水平",
                courseThPE2: "综合目标",
                courseThPE3: "听说读写",
                courseThPE4: "字词水平",
                courseTh5: "学习主题",
                JoinT1: "加入知行 免费试听",
                JoinT2: "赠送试听，无任何费用",
                JoinT3: "便捷注册，了解课程详情",
                JoinT4: "立刻预约 免费试听",
                JoinT4PE: "立刻预约 免费试听",
            },
            Medium: {
                title1: "以优质的师资保证教学质量",
                text1: `
                <p style="margin-top:0.09rem;">知行教师全部来自于教育部直属6大师范院</p>
                <p>校，汉语言文学专业，3年以上一线教学经</p>
                <p style="margin-bottom:0.07rem;">验；80%以上老师拥有211硕士以上学历。</p>
                `,
                textPE1: `
                <p style="margin-top:0.2rem;">知行教师全部来自于教育部直属6大师</p>
                <p>范院校，汉语言文学专业，3年以上一</p>
                <p>线教学经验；80%以上老师拥有211硕</p>
                <p style="margin-bottom:0.15rem;">士以上学历。</p>
                `,
                text11: `
                <p>知行教研组与西南大学、华中师范大学文学</p>
                <p>院合作，聘请高校专家教授，为知行定制课</p>
                <p>程教研。</p>
                `,
                textPE11: `
                <p>知行教研组与西南大学、华中师范大学</p>
                <p>文学院合作，聘请高校专家教授，为知</p>
                <p>行定制课程教研。</p>
                `,
                title2: "以趣味和启发为教学原则",
                text2: `
                <p style="margin-top:0.05rem;">通过深入浅出的教学方式，启发学生主动思</p>
                <p>考，发散思维，将生涩难懂的知识点以生动</p>
                <p>有趣的方式传授给学生。帮助学生对知识点</p>
                <p style="margin-bottom:0.05rem;">学以致用，融会贯通。</p>
                `,
                textPE2: `
                <p style="margin-top:0.18rem;">通过深入浅出的教学方式，启发学生主</p>
                <p>动思考，发散思维，将生涩难懂的知识</p>
                <p>点以生动有趣的方式传授给学生。帮助</p>
                <p style="margin-bottom:0.15rem;">学生对知识点学以致用，融会贯通。</p>
                `,
                text21: `
                <p>主题式的文化故事和叙事场景，引导学生沉</p>
                <p>浸式感知，帮助学生穿透语言工具，深入到</p>
                <p>文化现象和思维方式的认识当中。</p>
                `,
                textPE21: `
                <p>主题式的文化故事和叙事场景，引导学</p>
                <p>生沉浸式感知，帮助学生穿透语言工具，</p>
                <p>深入到文化现象和思维方式的认识当中。</p>
                `,
                title3: `坚持学员为本的教学理念`,
                text3: `
                <p style="margin-top:0.06rem;">以学生的角度为出发点，设计和布置问题，</p>
                <p style="margin-bottom:0.06rem;">引导学生用自己的思维去理解和消化知识。</p>
                `,
                textPE3: `
                <p style="margin-top:0.2rem;">以学生的角度为出发点，设计和布置问</p>
                <p>题，引导学生用自己的思维去理解和消</p>
                <p style="margin-bottom:0.15rem;">化知识。</p>
                `,
                text31: `
                <p>拒绝生硬的教导和传授，拒绝绝对的权威</p>
                <p>和准确，鼓励学生自主思考和认知。任何</p>
                <p>知识都有逐步深化和完善的过程，只有学</p>
                <p>生自己理解和认可的，才是真正的收获。</p>
                `,
                textPE31: `
                <p>拒绝生硬的教导和传授，拒绝绝对的权</p>
                <p>威和准确，鼓励学生自主思考和认知。</p>
                <p>任何知识都有逐步深化和完善的过程，</p>
                <p>只有学生自己理解和认可的，才是真正</p>
                <p>的收获。</p>
                `,
                title4: `系统兼顾个性的教学方式`,
                text4: `
                <p style="margin-top:0.06rem;">将中文教学中的共性、高频、重要知识点进</p>
                <p>行模块化归集，形成模块知识库，适应不同</p>
                <p>基础和目标的学生。无论学生从哪个级别开</p>
                <p style="margin-bottom:0.06rem;">始课程，都能方便迅速地补齐薄弱和缺漏。</p>
                `,
                textPE4: `
                <p style="margin-top:0.2rem;">将中文教学中的共性、高频、重要知识</p>
                <p>点进行模块化归集，形成模块知识库，</p>
                <p>适应不同基础和目标的学生。无论学生</p>
                <p>速从哪个级别开始课程，都能方便迅地</p>
                <p style="margin-bottom:0.15rem;">补齐薄弱和缺漏。</p>
                `,
                text41: `
                <p>通过灵活调用这套知识模块库，课程的个性</p>
                <p>化和系统化得以兼顾，在提高学生潜力分数</p>
                <p>的同时保证学习的效率。</p>
                `,
                textPE41: `
                <p>通过灵活调用这套知识模块库，课程的</p>
                <p>个性化和系统化得以兼顾，在提高学生</p>
                <p>潜力分数的同时保证学习的效率。</p>
                `,
                title5: `学考一体的教学策略`,
                text5: `
                <p style="margin-top:0.03rem;">深入学、考第一线，从基础中文层次到选拔</p>
                <p>考试层次，对知识体系进行系统梳理、梯度</p>
                <p>划分，形成知识脉络框架，中文进阶地图。</p>
                <p style="margin-bottom:0.05rem;">在此基础上研发课程体系，形成知识模块库。</p>
                `,
                textPE5: `
                <p style="margin-top:0.12rem;">深入学、考第一线，从基础中文层次到</p>
                <p>选拔考试层次，对知识体系进行系统梳</p>
                <p>理、梯度划分，形成知识脉络框架，中</p>
                <p>文进阶地图。在此基础上研发课程体系，</p>
                <p style="margin-bottom:0.08rem;">形成知识模块库。</p>
                `,
                text51: `
                <p>以考试大纲为目标，学生水平为基础，知识</p>
                <p>模块系统为辅助，全面提高听说读写各项能</p>
                <p>力，帮助学生形成完备自如的知识体系。</p>
                `,
                textPE51: `
                <p>以考试大纲为目标，学生水平为基础，</p>
                <p>知识模块系统为辅助，全面提高听说读</p>
                <p>写各项能力，帮助学生形成完备自如的</p>
                <p>知识体系。</p>
                `,
            },
            CourseType: {
                qsn11: ` 
                <div class="html_qsn11">
                <div>能基本理解与生活密切相关的简单语料，</div>
                <div>就常见话题进行简单沟通，介绍自己；</div>
                <div>掌握汉语拼音，能较熟练地拼读文章。</div>
                </div>
                `,
                qsn12: `
                <div class="html_qsn12">
                <div>听:基本听懂生活中的常见语料和课堂用语；</div>
                <div>说:句调基本准确，能简单造句及回答提问；</div>
                <div>读:准确、流利、有感情地朗读课文；</div>
                <div>写:默写规定的基本汉字，掌握笔画和笔顺；</div>
                </div>
                `,
                qsn13: `
                <div class="html_qsn1315">
                <div>识读1600个常用汉字，正确书写800个</div>
                <div>常用汉字。初步辨别字音、字形、字义</div>
                <div>了解汉字与词的关系。</div>
                </div>
                `,
                qsn21: `
                <div class="html_qsn11">
                <div>能理解与生活、学习相关的语言材料，</div>
                <div>能就熟悉的话题组织简单的语段；初步</div>
                <div>掌握重音、停顿、语调等表达技巧。</div>
                </div>
                `,
                qsn22: `
                <div class="html_qsn12">
                <div>听:听懂生活、学习中常见话语和要求；</div>
                <div>说:参与简单的对话，表达个人观点和需求；</div>
                <div>读:阅读简短书面材料，体会文章思想情感；</div>
                <div>写:用简单词语和句式，进行完整篇章书写；</div>
                </div>
                `,
                qsn23: `
                <div class="html_qsn13">
                <div>识读2500个常用汉字，正确书写1700</div>
                <div>个常用汉字。进一步辨识字音、字形、</div>
                <div>字义，了解组字成分、部件、偏旁，准</div>
                <div>确理解话语中的词汇意义。</div>
                </div>
                `,

                qsn31: `
                <div class="html_qsn11">
                <div>能理解社交场合中较简单的语言材料，</div>
                <div>抓住重点；就熟悉的话题与他人进行</div>
                <div>连贯交流，会使用基本的交际策略。</div>
                </div>
                `,
                qsn32: `
                <div class="html_qsn12">
                <div>听:理解论述性及议论性表达，抓住重点；</div>
                <div>说:就一般性话题与人交流，连贯表达看法；</div>
                <div>读:掌握多种文体阅读技巧，快速搜索信息；</div>
                <div>写:进行多种文体简短写作，表达准确恰当；</div>
                </div>
                `,
                qsn33: `
                <div class="html_qsn13">
                <div>识读3000个常用汉字，正确书写2500</div>
                <div>个常用汉字。了解较复杂的字、词结构</div>
                <div>规则，基本具备辨识字音、辨别字形、</div>
                <div>掌握字义的能力。</div>
                </div>
                `,
                qsnPE11: `
                <div class="html_ib11">
                能基本理解与生活密切相关的简单语料</br>
                ，就常见话题进行简单沟通，介绍自己</br>
                ；掌握汉语拼音，能较熟练地拼读文章。
                </div>
                `,
                qsnPE12: `
                <div class="html_ib12">
                <div>听:基本听懂生活中的常见语料和课堂用语；</div>
                <div>说:句调基本准确，能简单造句及回答提问；</div>
                <div>读:准确、流利、有感情地朗读课文；</div>
                <div>写:默写规定的基本汉字，掌握笔画和笔顺；</div>
                </div>
                `,
                qsnPE13: `
                <div class="html_ib13">
                识读1600个常用汉字，正确书写800个</br>
                常用汉字。初步辨别字音、字形、字义</br>
                ，了解汉字与词的关系。
                </div>
                `,
                qsnPE21: `
                <div class="html_ib11">
                能理解与生活、学习相关的语言材料，<br/>
                能就熟悉的话题组织简单的语段；初步<br/>
                掌握重音、停顿、语调等表达技巧。
                </div>
                `,
                qsnPE22: `
                <div class="html_ib12">
                <div>听:听懂生活、学习中常见话语和要求；</div>
                <div>说:参与简单的对话，表达个人观点和需求；</div>
                <div>读:阅读简短书面材料，体会文章思想情感；</div>
                <div>写:用简单词语和句式，进行完整篇章书写；</div>
                </div>
                `,
                qsnPE23: `
                <div class="html_ib131">
                识读2500个常用汉字，正确书写1700<br/>
                个常用汉字。进一步辨识字音、字形、<br/>
                字义，了解组字成分、部件、偏旁，准<br/>
                确理解话语中的词汇意义。
                </div>
                `,
                qsnPE31: `
                <div class="html_ib11">
                能理解社交场合中较简单的语言材料，<br/>
                抓住重点；就熟悉的话题与他人进行连<br/>
                贯交流，会使用基本的交际策略。
                </div>
                `,
                qsnPE32: `
                <div class="html_ib12">
                <div>听:理解论述性及议论性表达，抓住重点；</div>
                <div>说:就一般性话题与人交流，连贯表达看法；</div>
                <div>读:掌握多种文体阅读技巧，快速搜索信息；</div>
                <div>写:进行多种文体简短写作，表达准确恰当；</div>
                </div>
                `,
                qsnPE33: `
                <div class="html_ib131">
                识读3000个常用汉字，正确书写2500<br/>
                个常用汉字。了解较复杂的字、词结构<br/>
                规则，基本具备辨识字音、辨别字形、<br/>
                掌握字义的能力。
                </div>
                `,

                hsk11: `
                <div class="html_hsk11">
                <div>能理解最基本的、与个人日常和生活</div>
                <div>密切相关的、简单语言材料。</div>
                </div>
                `,
                hsk12: `
                <div class="html_hsk12">
                <div>听:听懂最基本的课堂指示话语和要求；</div>
                <div>说:跟读、复述所学词句，回答简单问候；</div>
                <div>读:识别拼音和简单汉字、词语；</div>
                <div>写:模仿课堂所教授的基本汉字；</div>
                </div>
                `,
                hsk13: `
                <div class="html_hsk13">
                <p>150个字词——与日常生活、学校有关</p>
                <p>的最基本词语。</p>
                </div>
                `,

                hsk21: `
                <div class="html_hsk11">
                <p>能就日常生活的常见话题以简单的方式</p>
                <p>与他人沟通、介绍自己。</p>
                </div>
                `,
                hsk22: `
                <div class="html_hsk12">
                <p>听:理解日常生活基本语料；</p>
                <p>说:句调基本准确、能模仿造句；</p>
                <p>读:认读基本汉字、词句及简短的句子；</p>
                <p>写:默写基本汉字，掌握笔画和笔顺；</p>
                </div>
                `,
                hsk23: `
                <div class="html_hsk13"><p>300个字词——与日常生活、学校有关</p>
                <p>的最基本词语。</p>
                </div>
                `,

                hsk31: `
                <div class="html_hsk11">
                <p>"能就常见的生活话题与他人沟通，并就</p>
                <p>这些话题组织简单的语段。</p>
                </div>
                `,
                hsk32: `
                <div class="html_hsk12">
                <p>听:听懂日常生活和学习中简单的交谈；</p>
                <p>说:参与简单对话，基本表达个人观点；</p>
                <p>读:阅读生活和学习中常见的简短材料；</p>
                <p>写:用简单的词语描述个人生活相关信息；</p>
                </div>
                `,
                hsk33: `
                <div class="html_hsk13"><p>600个常用字词，了解组字成分、部件、</p>
                <p>偏旁部首，辨识字音、字形、字义。</p>
                </div>
                `,

                hsk41: `
                <div class="html_hsk11">
                <p>"能理解社交场合中比较简单、熟悉的</p>
                <p>&nbsp;语言材料，抓住重点，把握细节。</p>
                </div>
                `,
                hsk42: `
                <div class="html_hsk12">
                <p>听:听懂工作、社交场合中的谈话和发言；</p>
                <p>说:就一般工作、社交话题与人沟通交谈；</p>
                <p>读:看懂日常较浅显的介绍、说明性文字；</p>
                <p>写:能按一定格式书写简短的篇章；</p>
                </div>
                `,
                hsk43: `
                <div class="html_hsk13"><p>1200个常用字词，了解较复杂的汉字</p>
                <p>结构，了解汉语词句的结构规律。</p>
                </div>
                `,

                hsk51: `
                <div class="html_hsk51">
                <p >能使用一些交际策略参与谈话，包括</p>
                <p>专业领域内的一般话题，连贯、清晰</p>
                <p>地表达自己的意见。</p>
                </div>
                `,
                hsk52: `
                <div class="html_hsk12">
                <p>听:听懂专业和工作上的一般性谈论；</p>
                <p>说:清晰地陈述自己的意见、理由和观点；</p>
                <p>读:看懂一定长度、较复杂的语言材料；</p>
                <p>写:就特定话题进行描述、记录或说明；</p>
                </div>
                `,
                hsk53: `
                <div class="html_hsk13">
                <p>2500个常用字词，掌握汉字构型规律</p>
                <p>，音、形、义运用基本正确。</p>
                </div>
                `,

                hsk61: `
                <div class="html_hsk51">
                <p >能理解多种场合下多种主题的复杂语言</p>
                <p>材料，能进行概括和分析，参与讨论</p>
                <p>和交流，掌握一部分成语和俗语。</p>
                </div>
                `,
                hsk62: `
                <div class="html_hsk12">
                <p>听:听懂多种场合下较复杂的谈话和发言；</p>
                <p>说:熟练地以不同语速进行口语表达；</p>
                <p>读:达到一定的阅读速率和效率；</p>
                <p>写:较准确、简练、生动地使用书面语言；</p>
                </div>
                `,
                hsk63: `
                <div class="html_hsk63">
                <p >5000个常用字词，掌握汉字构型规律</p>
                <p>，音、形、义运用基本正确，听说读写</p>
                <p>能力融会贯通。</p>
                </div>
                `,
                hskPE11: `
                <div class="html_ib11">
                能理解最基本的、与个人日常和生活</br>
                密切相关的、简单语言材料。
                </div>
                `,
                hskPE12: `
                <div class="html_ib12">
                听:听懂最基本的课堂指示话语和要求；</br>
                说:跟读、复述所学词句，回答简单问候；</br>
                读:识别拼音和简单汉字、词语；</br>
                写:模仿课堂所教授的基本汉字；</br>
                </div>
                `,
                hskPE13: `
                <div class="html_ib13">
                150个常用字——与日常生活、学校有<br/>
                关的最基本词语。
                </div>
                `,
                hskPE21: `
                <div class="html_ib11">
                能就日常生活的常见话题以简单的方<br/>
                式与他人沟通、介绍自己。
                </div>
                `,
                hskPE22: `
                <div class="html_ib12">
                听:理解日常生活基本语料；</br>
                说:句调基本准确、能模仿造句；</br>
                读:认读基本汉字、词句及简短的句子；</br>
                写:默写基本汉字，掌握笔画和笔顺；</br>
                </div>
                `,
                hskPE23: `
                <div class="html_ib13">
                300个常用字——与日常生活、学校有<br/>
                关的最基本词语。
                </div>
                `,
                hskPE31: `
                <div class="html_ib11">
                能就常见的生活话题与他人沟通，并<br/>
                就这些话题组织简单的语段。
                </div>
                `,
                hskPE32: `
                <div class="html_ib12">
                听:听懂日常生活和学习中简单的交谈；</br>
                说:参与简单对话，基本表达个人观点；</br>
                读:阅读生活和学习中常见的简短材料；</br>
                写:用简单的词语描述个人生活相关信息；</br>
                </div>
                `,
                hskPE33: `
                <div class="html_ib13">
                600个常用字，了解组字成分、部件、</br>
                偏旁部首，辨识字音、字形、字义。
                </div>
                `,
                hskPE41: `
                <div class="html_ib11">
                能理解社交场合中比较简单、熟悉的<br/>
                语言材料，抓住重点，把握细节。
                </div>
                `,
                hskPE42: `
                <div class="html_ib12">
                听:听懂工作、社交场合中的谈话和发言；</br>
                说:就一般工作、社交话题与人沟通交谈；</br>
                读:看懂日常较浅显的介绍、说明性文字；</br>
                写:能按一定格式书写简短的篇章；</br>
                </div>
                `,
                hskPE43: `
                <div class="html_ib13">
                1200个常用字，了解较复杂的汉字结</br>
                构，了解汉语词句的结构规律。
                </div>
                `,
                hskPE51: `
                <div class="html_ib51">
                能使用一些交际策略参与谈话，包括</br>
                专业领域内的一般话题，连贯、清晰</br>
                地表达自己的意见。
                </div>
                `,
                hskPE52: `
                <div class="html_ib12">
                听:听懂专业和工作上的一般性谈论；</br>
                说:清晰地陈述自己的意见、理由和观点；</br>
                读:看懂一定长度、较复杂的语言材料；</br>
                写:就特定话题进行描述、记录或说明；</br>
                </div>
                `,
                hskPE53: `
                <div class="html_ib13">
                2500个常用字，掌握汉字构型规律，</br>
                音、形、义运用基本正确。
                </div>
                `,
                hskPE61: `
                <div class="html_ib51">
                能理解多种场合下多种主题的复杂语</br>
                言材料，能进行概括和分析，参与讨</br>
                论和交流，掌握一部分成语和俗语。
                </div>
                `,
                hskPE62: `
                <div class="html_ib12">
                听:听懂多种场合下较复杂的谈话和发言；</br>
                说:熟练地以不同语速进行口语表达；</br>
                读:达到一定的阅读速率和效率；</br>
                写:较准确、简练、生动地使用书面语言；</br>
                </div>
                `,
                hskPE63: `
                <div class="html_ib63">
                5000个常用字，掌握汉字构型规律，</br>
                音、形、义运用基本正确，听说读写</br>
                能力融会贯通。
                </div>
                `,
                aptitle: "AP 中文课程",
                apPEtitle: `AP<br/>中文`,
                ap11: `
                <div class="html_ap11" >
                <div>
熟悉考试题型，掌握高频考点，强化高频
                </div>
           <div>
主题语料练习和积累；围绕考试大纲
            </div>
       <div>
5C（Communities、Communication、
            </div>
       <div>
Comparisons、Cultures、Connection
                </div>
           <div>
）主题加强听、说、读、写各项能力；
            </div>
       <div>
熟悉并理解文化现象和文化观念；熟练地
            </div>
       <div>
进行中英意向转换；掌握答题策略、得分
            </div>
       <div>
技巧。
            </div>
                </div>
                `,
                apPE11: `
                <div class="html_ib11" >
                <div>
                熟悉考试题型，掌握高频考点，强化
                </div>
           <div>
           高频
           主题语料练习和积累；围绕考试
            </div>
       <div>
       大纲
       5C（Communities、Communi
            </div>
       <div>
       cation、
       Comparisons、Cultures、
                </div>
           <div>
           Connection
           ）主题加强听、说、读、
            </div>
       <div>
       写各项能力；
       熟悉并理解文化现象和
            </div>
       <div>
       文化观念；熟练地
       进行中英意向转换；
            </div>
       <div>
       掌握答题策略、得分技巧。
            </div>
                </div>
                `,
                ap12: `
                <div class="html_ap12">
                <div>
听：理解并识别常见语料中的重要讯息、
                </div>
           <div>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;叙事逻辑、感情表达；
                </div>
           <div>
说：能够流利地进行话题演绎、逻辑诠释
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;、意见表达；
                 </div>
            <div>
读：准确辨析词、句、章结构及含义，
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;认识文化习俗、现象和观念，完成
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中英意向转换；
                 </div>
            <div>
写：掌握不同文体、不同题材书写技巧，
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;掌握几种经典的书写框架；
            </div>
            </div>
                `,
                apPE12: `
                <div class="html_ib12">
                <div>
听：理解并识别常见语料中的重要讯息、
                </div>
           <div>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;叙事逻辑、感情表达；
                </div>
           <div>
说：能够流利地进行话题演绎、逻辑诠释
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;、意见表达；
                 </div>
            <div>
读：准确辨析词、句、章结构及含义，
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;认识文化习俗、现象和观念，完成
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中英意向转换；
                 </div>
            <div>
写：掌握不同文体、不同题材书写技巧，
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;掌握几种经典的书写框架；
            </div>
            </div>
                `,
                ap13: `
                <div class="html_ap13" >

                <div>
                中华文化及观念；
    </div>
    <div>
                传统节日及习俗；
    </div>
    <div>
                旅游景区及古建筑；
</div>
<div>
         文艺著作及四大名著；
    </div>
<div>
               中华饮食及八大菜系；
    </div>
    <div>
                中国书画及文房四宝；
    </div>
    <div>
                四大发明及现代科技；
    </div>
    <div>
                中医及哲学观；
</div>

<div class="you_tit">
八大主题：
</div>

        </div>
                `,
                apPE13: `
                <div class="html_ib13" >

                <div>
                中华文化及观念；
    </div>
    <div>
                传统节日及习俗；
    </div>
    <div>
                旅游景区及古建筑；
</div>
<div>
         文艺著作及四大名著；
    </div>
<div>
               中华饮食及八大菜系；
    </div>
    <div>
                中国书画及文房四宝；
    </div>
    <div>
                四大发明及现代科技；
    </div>
    <div>
                中医及哲学观；
</div>

<div class="you_tit">
八大主题：
</div>

        </div>
                `,

                idtitle: `
<p style="text-align: center;margin-top:0.6rem;">
    IB 中文（语言 B）
</p>
                `,
                idPEtitle: `
                IB<br/>中文B
                `,
                ib11: `
                <div class="html_ib11">
                <div >
熟悉考试题型，掌握高频考点、主题；围
    </div>
<div>
绕5大考纲主题（身份认同、经历体验、
    </div>
<div>
发明创造、社会组织、共享地球）加强
    </div>
<div>
听说读写各项能力；熟悉并理解文化现象
        </div>
    <div>
和文化观念；熟练地进行中英意向转换；
    </div>
<div>

掌握答题策略、得分技巧。
    </div>
</div>
                `,
                ibPE11: `
                <div class="html_ib11">
                <div >
        熟悉考试题型，掌握高频考点、主题；
            </div>
        <div>
        围绕5大考纲主题（身份认同、经历体
            </div>
        <div>
        验、发明创造、社会组织、共享地球）
            </div>
        <div>
        加强听说读写各项能力；熟悉并理解文
        </div>
        <div>
        化现象和文化观念；熟练地进行中英意
        </div>
        <div>
        向转换；掌握答题策略、得分技巧。
        </div>
</div>
                `,
                ib12: `
                <div class="html_ib12">
            <div>
听：理解并识别常见语料中的重要讯息、叙
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;事逻辑、感情表达；
    </div>
<div>
说：流利地参与对话（语言准确、词汇句式
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;丰富），进行逻辑诠释、意见表达；
    </div>
<div>
读：准确辨析词、句、章结构及含义，强化
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;长词/成语的掌握，认识文化现象和
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;观念，完成中英意向转换；
    </div>
<div>
写：掌握不同文体、不同题材书写技巧，词
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;汇语法丰富准确、逻辑与连贯性良好；
</div>
</div>
                `,
                ibPE12: `
                <div class="html_ib12">
            <div>
听：理解并识别常见语料中的重要讯息、叙
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;事逻辑、感情表达；
    </div>
<div>
说：流利地参与对话（语言准确、词汇句式
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;丰富），进行逻辑诠释、意见表达；
    </div>
<div>
读：准确辨析词、句、章结构及含义，强化
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;长词/成语的掌握，认识文化现象和
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;观念，完成中英意向转换；
    </div>
<div>
写：掌握不同文体、不同题材书写技巧，词
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;汇语法丰富准确、逻辑与连贯性良好；
</div>
</div>
                `,
                ib13: `
                <div class="html_ib13" >

                <div>
	             身份认同（生活方式、健康与幸福、信仰与
    </div>
    <div>
                       价值观、次文化、语言与认同）；
    </div>
    <div>
          经历体验（休闲、假日与旅行、生活故事、
</div>
<div>
                 生活礼仪、习俗与传统、移民与迁徙）；
    </div>
<div>
         发明创造（娱乐、艺术表现、技术、科学
    </div>
    <div>
                创新、交流与媒体）；
    </div>
    <div>
        社会组织（社区、社会参与、职场、法律与
    </div>
    <div>
                       秩序、社会关系、教育）；
</div>
    <div>
                共享地球（环境、和平与冲突、伦理道德、
</div>
    <div>
                       城乡环境、人权、平等、全球化 
</div>

<div class="you_tit">
五大考纲
<p> . &nbsp;.</p>
</div>

        </div>
                `,
                ibPE13: `
                <div class="html_ib13" >

                <div>
                身份认同（生活方式、健康与幸福、
    </div>  
    <div>
    &nbsp; &nbsp; &nbsp; &nbsp;信仰与价值观、语言与认同）；
    </div>
    <div>
    经历体验（休闲、假日与旅行、生活
    </div>
    <div>
    &nbsp; &nbsp; &nbsp; &nbsp;礼仪习俗与传统、移民与迁徙）；
        </div>
    <div>
    发明创造（娱乐、艺术表现、技术、
    </div>
    <div>
    &nbsp; &nbsp; &nbsp; &nbsp;科学创新、交流与媒体）；
    </div>                  
    <div>
    社会组织（社区、社会参与、职场、
    </div>
    <div>
    &nbsp; &nbsp; &nbsp; &nbsp;法律与秩序、社会关系、教育）；
</div>
    <div>
    共享地球（和平与冲突、伦理道德、
</div>
    <div>
    &nbsp; &nbsp; &nbsp; &nbsp;环境人权、平等、全球化）；
</div>

<div class="you_tit">
五大考纲
<p> . &nbsp;.</p>
</div>

        </div>
                `,
                oltitle: `
                <p style="text-align: center;margin-top:0.5rem;">
    O-level中文&nbsp;
</p>
<p style="text-align: center;">
    （综合）
</p>
                `,
                olPEtitle: `O-level<br/>
                综合
                `,
                ol11: `

                <div class="html_ol11">
                <div>
熟悉考试题型，掌握典型语料模板；针对
    </div>
<div>
考试大纲加强听说读写各项能力；加强字
    </div>
<div>
词（成语）积累，准确理解词义；掌握常
    </div>
<div>
见的语料表达；熟练地进行中英意向转换
    </div>
<div>
；训练答题策略、得分技巧。
    </div>
</div>
                `,
                olPE11: `
                <div class="html_ib11">
                <div>
熟悉考试题型，掌握典型语料模板；针
    </div>
<div>
对考试大纲加强听说读写各项能力；加
    </div>
<div>
强字词（成语）积累，准确理解词义；
    </div>
<div>
掌握常见的语料表达；熟练地进行中英
    </div>
<div>
意向转换；训练答题策略、得分技巧。
    </div>
</div>
                `,
                ol12: `
 
                <div class="html_ol12">
                <div>
    听：识别语料主旨，理解关键信息，分清叙

    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;事逻辑、体会情感表达；

    </div>
<div>
    说：流利朗读语料、参与对话，流利地进行

    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;简单的逻辑诠释、意见表达；

    </div>
<div>
    读：辨析文章大体结构、主旨与情感，积累

    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;词汇量，强化词义辨析，提高阅读速度

    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;，完成中英意向转换；

    </div>
<div>
    写：掌握典型文体写作模板，积累时政题材

    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;语料，词汇较为丰富、整体结构清晰；
    </div>
    </div>
                `,
                olPE12: `
 
                <div class="html_ib12">
                <div>
    听：识别语料主旨，理解关键信息，分清叙
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;事逻辑、体会情感表达；
    </div>
<div>
    说：流利朗读语料、参与对话，流利地进行
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;简单的逻辑诠释、意见表达；
    </div>
<div>
    读：辨析文章大体结构、主旨与情感，积累
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;词汇量，强化词义辨析，提高阅读速度
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;，完成中英意向转换；
    </div>
<div>
    写：掌握典型文体写作模板，积累时政题材
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;语料，词汇较为丰富、整体结构清晰；
    </div>
    </div>
                `,
                ol13: `
                <div class="html_ol13" >

                <div>
时政新闻
    </div>
    <div>
叙事文体拓展
    </div>
    <div>
议论文体拓展
</div>
<div>
电邮文体拓展
    </div>
    <div>
材料作文拓展
    </div>
    <div>
    成语积累
    </div>
    <div>
    词义辨析
</div>
<div>
<div>
口语训练
</div>
    </div>

<div class="you_tit">
针对性选择: 
</div>

        </div
                `,
                olPE13: `
                <div class="html_ib13" >

                <div>
时政新闻
    </div>
    <div>
叙事文体拓展
    </div>
    <div>
议论文体拓展
</div>
<div>
电邮文体拓展
    </div>
    <div>
材料作文拓展
    </div>
    <div>
    成语积累
    </div>
    <div>
    词义辨析
</div>
<div>
<div>
口语训练
</div>
    </div>

<div class="you_tit">
针对性选择<span>:</span> 
</div>

        </div
                `,
            },
            Character: {
                title1: "一线专业师资授课",
                titlePE1: `一线专业师资授课 <br/> 为学生提供最优质的教学资源`,
                titlePE12: `
                <div class="txt_box">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;知行授课教师全部来自于教育部直属<br/>
                6大师范院校，汉语言文学专业，3年以上<br/>
                一线教学经验，80%以上老师拥有211硕士<br/>
                以上学历，坚持以最优质的师资力量打造<br/>
                最专业的教学团队。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;知行与西南大学、华中师范大学文学<br/>
                院合作，聘请高效专家教授，为知行定制<br/>
                课程教研。<br/>
                </div>
                `,
                titlePE2: `趣味教学沉浸感知 <br/> 启发学生活学活用融会贯通`,
                titlePE22: `
                <div class="txt_box txt_box_2">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;知行课堂通过深入浅出的教学方法，启<br/>
                发学生主动思考，发散思维，将生涩难懂的<br/>
                知识点以生动有趣的方式传授给学生，结合<br/>
                经典的文化故事和叙事场景，帮助学生对知<br/>
                识点学以致用，融会贯通。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主题式的文化故事和叙事场景，引导学<br/>
                生沉浸式感知，帮助学生穿透语言工具，深<br/>
                入到文化现象和思维方式的认识当中。<br/>
                </div>
                `,
                titlePE3: `分级教学系统衔接 <br/> 针对不同需求灵活定制教案`,
                titlePE32: `
                <div class="txt_box txt_box_3">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;知行将中文教学中的共性、高频、重要<br/>
                知识点进行模块化归集，形成模块知识库，<br/>
                适应不同基础和目标的学生。无论学生从哪<br/>
                个级别开始课程，都能方便迅速地补齐薄弱<br/>
                和缺漏。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过灵活调用这套知识模块库，课程的<br/>
                个性化和系统 化得以兼顾，在提高学生潜力<br/>
                分数的同时保证学习的效率。<br/>
                </div>
                `,
                titlePE4: `换位教学思维引领 <br/> 为学生提供最优质的教学资源`,
                titlePE42: `
                <div class="txt_box">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;知行授课教师全部来自于教育部直属<br/>
                6大师范院校，汉语言文学专业，3年以上<br/>
                一线教学经验，80%以上老师拥有211硕士<br/>
                以上学历，坚持以最优质的师资力量打造<br/>
                最专业的教学团队。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;知行与西南大学、华中师范大学文学<br/>
                院合作，聘请高效专家教授，为知行定制<br/>
                课程教研。<br/>
                </div>
                `,
                titlePE5: `个性教学举一反三 <br/> 针对海外学生特点量身定制`,
                titlePE52: `
                <div class="txt_box txt_box_5">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;知行重视学生本身的语言基础和特点，<br/>
                强调从学生的个性出发，融入共性知识内容<br/>
                ；帮助学生通过一个主题点的学习，带动一<br/>
                个知识面的掌握；同时结合海外华文的应用<br/>
                和生活场景，回归地道的汉语用法和规范。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过激发学生优势板块的潜力，提升学<br/>
                生学习兴趣，从而补齐弱势板块的短板。<br/>
                </div>
                `,
                titlePE6: `学考一体知识完备 <br/> 听说读写考全面能力提升`,
                titlePE62: `
                <div class="txt_box txt_box_2">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深入学、考第一线，从基础中文层次到<br/>
                选拔考试层次，对知识体系进行系统梳理、<br/>
                梯度划分，形成知识脉络框架，中文进阶地<br/>
                图。在此基础上研发课程体系，形成知识模<br/>
                块库。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以考试大纲为目标，学生水平为基础，<br/>
                知识模块系统为辅助，全面提高听说读写各<br/>
                项能力，帮助学生形成完备自如的知识体系<br/>
                </div>
                `,
                title11: "为学生提供最优质的教学资源",
                text1: `<p style="line-height: 1.5em">
          &nbsp; &nbsp; &nbsp; &nbsp;
          知行授课教师全部来自于教育部直属6大师范院校，汉语言文学专业，3年以上一线教学经验，80%以上老师拥有211硕士以上学历，坚持以最优质的师资力量打造最专业的教学团队。
        </p>
        <p style="line-height: 1.5em">
          &nbsp; &nbsp; &nbsp; &nbsp;
          知行与西南大学、华中师范大学文学院合作，聘请高效专家教授，为知行定制课程教研。
        </p>
                `,
                title2: "趣味教学沉浸感知",
                title21: "启发学生活学活用融会贯通",
                text2: `
                <p style="line-height: 1.5em;">
                &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; 知行课堂通过深入浅出的教学方法，
</p>
<p style="line-height: 1.5em;">
&nbsp; 启发学生主动思考，发散思维，将生涩难懂
</p>
<p style="line-height: 1.5em;">
&nbsp; 的知识点以生动有趣的方式传授给学生，结
</p>
<p style="line-height: 1.5em;">
&nbsp; 合经典的文化故事和叙事场景，帮助学生对
</p>
<p style="line-height: 1.5em;">
&nbsp; 知识点学以致用，融会贯通。
</p>
<p style="line-height: 1.5em;">
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 主题式的文化故事和叙事场景，引导
</p>
<p style="line-height: 1.5em;">
&nbsp; 学生沉浸式感知，帮助学生穿透语言工具，
</p>
<p style="line-height: 1.5em;">
&nbsp; 深入到文化现象和思维方式的认识当中。
</p>
                `,

                title3: "分级教学系统衔接",
                title31: "针对不同需求灵活定制教案",
                text3: `
                <p style="line-height: 1.5em;">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 知行将中文教学中的共性、高频、重
</p>
<p style="line-height: 1.5em;">
&nbsp; 要知识点进行模块化归集，形成模块知识
</p>
<p style="line-height: 1.5em;">
&nbsp; 库，适应不同基础和目标的学生。无论学生
</p>
<p style="line-height: 1.5em;">
&nbsp; 从哪个级别开始课程，都能方便迅速地补齐
</p>
<p style="line-height: 1.5em;">
&nbsp; 薄弱和缺漏。
</p>
<p style="line-height: 1.5em;">
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 通过灵活调用这套知识模块库，课程
</p>
<p style="line-height: 1.5em;">
&nbsp; 的个性化和系统化得以兼顾，在提高学生潜
</p>
<p style="line-height: 1.5em;">
&nbsp; 力分数的同时保证学习的效率。
</p>
<p>
    <br/>
</p>
                `,

                title4: "换位教学思维引领",
                title41: "以开放性的问题引导学生学习",
                text4: `
                <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; 知行的教研及授课体系都是以学生的
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;角度为出发点，来进行设计和布置，引导
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;学生用自己的思维去理解消化知识内容。
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; 知行拒绝生硬的教导和传授，拒绝绝
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;对的权威和准确，鼓励学生自主思考和认
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;知。任何知识都有逐步深化和完善的过程
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;，只有学生自己理解和认可的，才是真正
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;的收获。
            </p>
                `,

                title5: "个性教学举一反三",
                title51: "针对海外学生特点量身定制",
                text5: `
                <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;知行重视学生本身的语言基础和特点
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;，强调从学生的个性出发，去融入共性的
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;知识内容；帮助学生通过一个主题点的学
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;习，带动一个知识面的掌握；同时结合海
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;外华文的应用和生活场景，回归地道的汉
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;语用法和规范。
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; 通过激发学生优势板块的潜力，来
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;提升学生学习兴趣，从而补齐弱势板块的
            </p>
            <p style="line-height: 1.5em;">
                &nbsp;&nbsp;&nbsp;&nbsp;短板。
            </p>
                `,

                title6: "学考一体知识完备",
                title61: "听说读写考全面能力提升",
                text6: `
                <p style="line-height: 1.5em;">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 深入学、考第一线，从基础中文层次
</p>
<p style="line-height: 1.5em;">
&nbsp; 到选拔考试层次，对知识体系进行系统梳
</p>
<p style="line-height: 1.5em;">
&nbsp; 理、梯度划分，形成知识脉络框架，中文进
</p>
<p style="line-height: 1.5em;">
&nbsp; 阶地图。在此基础上研发课程体系，形成知
</p>
<p style="line-height: 1.5em;">
&nbsp; 识模块库。
</p>
<p style="line-height: 1.5em;">
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 以考试大纲为目标，学生水平为基础,
</p>
<p style="line-height: 1.5em;">
&nbsp; 知识模块系统为辅助，全面提高听说读写各
</p>
<p style="line-height: 1.5em;">
&nbsp; 项能力，帮助学生形成完备自如的知识体系
</p>
                `,
            },
            Teach: {
                tlang: "授课语言：",
                CHIN: "中文",
                ENG: "英文",
                KO: "韩文",
                CANT: "粤语",
                year: "年",
                hours: "",
            },
            Concept: `
            <p style="line-height: 1.5em">
            &nbsp; &nbsp; &nbsp; &nbsp;
             “知行”一词取自明代思想家王阳明的哲学观：“知行合一”。它强调在认知的过程中，
          </p>
          <p style="line-height: 1.5em">
          学习与实践，是密不可分的——在知中行、在行中知，互相辩证、交替提升。
        </p>
          <p>
            <br />
          </p>
          <p style="line-height: 1.5em">
            &nbsp; &nbsp; &nbsp; &nbsp;
            正如知行的教学理念——我们希望，中文课程的教授不仅仅局限于词汇、短语的记忆，
          </p>
          <p style="line-height: 1.5em">
            咿呀学语式的练习，因为中文除了是一种工具语言，更重要的，它也是一种文化现象和思维
          </p>
          <p style="line-height: 1.5em">
            方式。在知行的课堂，我们力求能够穿透繁复的语言工具本身，深入到文化现象和思维方式
          </p>
          <p style="line-height: 1.5em">
            的介绍当中。这对于今后希望在中文世界学习发展的学生来说，无疑是非常重要的。
          </p>
          <p>
            <br />
          </p>
          <p style="line-height: 1.5em">
            &nbsp; &nbsp; &nbsp; &nbsp;
            语言是文化的载体，一步到位式的学习观念是不可取的。只有通过在“知”与“行”当
          </p>
          <p style="line-height: 1.5em">
            中的反复磨练和矫正，才有可能真正掌握这门语言。知行的课堂，将是这一切开始的地方。
          </p>
          <p>
            <br />
          </p>
            `,
            Concept1: `
            “知行”中一词取自明代思想家王阳明的哲学观：“知<br/>
            行合一”。它强调在认知的过程中，学习与实践，是密不<br/>
            可分的——在知中行、在行中知，互相辩证、交替提升。
            `,
            Concept2: `正如知行的教学理念——我们希望，中文课程的教授<br/>
            不仅仅局限于词汇、短语的记忆，咿呀学语式的练习，因<br/>
            为中文除了是一种工具语言，更重要的，它也是一种文化<br/>
            现象和思维方式。在知行的课堂，我们力求能够穿透繁复<br/>
            的语言工具本身，深入到文化现象和思维方式的介绍当<br/>
            中。这对于今后希望在中文世界学习发展的学生来说，无<br/>
            疑是非常重要的。
            `,
            Concept3: `
            语言是文化的载体，一步到位式的学习观念是不可取<br/>
            的。只有通过在“知”与“行”当中的反复磨练和矫正，才有<br/>
            可能真正掌握这门语言。知行的课堂，将是这一切开始的<br/>
            地方。
            `,
            Concept1Button: "加载更多",
            Concept2Button: "收起",
            QA: {
                toptit1: `课程介绍`,
                toptit2: `购买问题`,
                toptit3: `使用细节`,
                toptitPE1: `课程介绍`,
                toptitPE2: `购买问题`,
                toptitPE3: `使用细节`,
                tit1: "知行的课程形式是怎样的？",
                tit2: "相比于其他平台，知行的优势是什么？",
                tit3: "家长该如何掌握孩子的学习情况呢？",
                tit4: "平时有没有作业？老师是否会批改？",
                tit5: "如何预约免费试听课？",
                tit6: "试听课的内容是什么？",
                tit7: "上课过程中可以为学生更换老师吗？",
                tit8: "知行的中文课程什么价格？",
                tit9: "知行接受什么样的付款方式？",
                tit10: "知行接受哪些币种进行付款？",
                tit11: "首次上课需要作些什么准备呢？",
                tit12: "首次上课需要作些什么准备呢？",
                tit13: "知行的线上课程对于网络有什么要求？",
                tit14: "忘记账号/登录密码，该怎么办？",
                titPE1: "知行的课程形式是怎样的？",
                titPE2: "相比于其他平台，知行的优势是什么？",
                titPE3: "家长该如何掌握孩子的学习情况呢？",
                titPE4: "平时有没有作业？老师是否会批改？",
                titPE5: "如何预约免费试听课？",
                titPE6: "试听课的内容是什么？",
                titPE7: "上课过程中可以为学生更换老师吗？",
                titPE8: "知行的中文课程什么价格？",
                titPE9: "知行接受什么样的付款方式？",
                titPE10: "知行接受哪些币种进行付款？",
                titPE11: "首次上课需要作些什么准备呢？",
                titPE12: "首次上课需要作些什么准备呢？",
                titPE13: "知行的线上课程对于网络有什么要求？",
                titPE14: "忘记账号/登录密码，该怎么办？",
                txt1: `知行采用线上课程，1V1的授课形式，基本课程时长为55分钟；目前开设课程有：青少年中文、HSK标准
                课程、O-LEVEL中文考试、AP中文考试、IB水平考试5种，学生可根据自身需求选取。`,
                txt2: `知行拥有国内最优质的中文师资力量：我们的教师全部来自于教育部直属6大师范院校，汉语言文学专业
                ，3年以上一线教学经验，80%以上老师拥有211硕士以上学历。
                知行与西南大学、华中师范大学文学院合作，聘请高效专家教授，为知行定制课程教研。
                知行与拓课云网络深度合作，为学生提供优质、稳定的网络连接，让您足不出户，就能拥有一堂优质、
                地道的中文课程。`,
                txt3: `知行在每一个阶段的学习结束后，会定期单独与家长开一堂“家长会”，对孩子这一段时间以来的学习
                情况进行介绍，也对学生课后的学习情况进行了解，在双方沟通的基础上更好的掌握学生的学习情况。
                同时，知行拥有课程记录回放功能，家长可在学习软件上回看详细的课程授课情况，对学生状态和老师
                授课质量进行监督。`,
                txt4: `在线上的课堂时间结束后，一般老师都会对本节课所学内容布置作业，以供学生练习，巩固本堂课的学习
                内容；之后，学生在学习软件上传作业，老师也会对学生作业进行认真的批改，并对作业中存在的问题
                进行反馈和讲解。
                而针对于有考试需求的学生，我们还会额外提供拓展性的习题作业、以及专门的考点题库，帮助学生更好
                的应对考试要求。`,
                txt5: `在成功注册知行账号之后，可扫码添加我们的客服人员（微信），客服人员会结合学生需求，为您匹配
                合适的老师，并为您安排好试听课程。
                您也可以点击“免费试听”，留下联系方式，随后我们的客服人员会跟您取得联系。`,
                txt6: `首先，有对课程设置的简要介绍，之后，会有教学内容的试讲环节。
                试听的过程，同时也是老师掌握学生基础水平的过程。老师会以之作参考，来展开之后的课程，也会对
                学生未来的中文学习提出具体的建议。`,
                txt7: `可以的。
                若您在学生学习过程中发现老师有不称职的地方，或者老师与学生难以成功磨合的情况，是随时可以提
                出更换老师的要求的。
                同时，考虑到课程体系的完整和连贯性，若学生能够长期跟着一个老师稳定的学习，则无疑是最理想的。
                所以，也请您对我们的老师保持一定的耐心，以及必要的信任。`,
                txt8: `我们的课程每堂约20美元左右（55分钟），具体根据课程种类的不同而略有区别。详细价格信息您可以
                注册后在会员中心查看，或询问我们的客服人员了解。`,
                txt9: `我们支持信用卡付款，包括Visa、Master card卡；Payoneer；微信；支付宝；银行间汇款等多种支付方式
                付款。
                `,
                txt10: `我们支持使用美元、人民币、新加坡币、欧元等币种进行付款。
                `,
                txt11: `首次上课前，请按照《知行中文教学系统用户指南》的指引，在智能设备上安装学习软件（为保证最佳的
                课程质量，建议尽量不要使用网页方式进入课堂）。
                安装好学习软件后按照软件指引测试摄像头、耳机、麦克风等设备的工作情况；
                在课程开始前，提前5-10分钟进入在线教室，保证课程的正常进行：
                `,
                txt12: `Windows/Mac电脑，apple/Android智能设备均可安装。`,
                txt13: `建议您采用实体网线的连接方式，或保证10M/S以上的无线连接速率。 
                学生上课期间尽量避免大流量的网络负荷，以保证课堂质量不受干扰。`,
                txt14: `您可以联系客服人员获取密码，您也可以在会员页面重新设置您的密码。 `,
                
                txtPE1: `知行采用线上课程，1V1的授课形式，基本课程时长为55分钟；目前开设课程有：青少年中文、HSK标准
                课程、O-LEVEL中文考试、AP中文考试、IB水平考试5种，学生可根据自身需求选取。`,
                txtPE2: `知行拥有国内最优质的中文师资力量：我们的教师全部来自于教育部直属6大师范院校，汉语言文学专业
                ，3年以上一线教学经验，80%以上老师拥有211硕士以上学历。
                知行与西南大学、华中师范大学文学院合作，聘请高效专家教授，为知行定制课程教研。
                知行与拓课云网络深度合作，为学生提供优质、稳定的网络连接，让您足不出户，就能拥有一堂优质、
                地道的中文课程。`,
                txtPE3: `知行在每一个阶段的学习结束后，会定期单独与家长开一堂“家长会”，对孩子这一段时间以来的学习
                情况进行介绍，也对学生课后的学习情况进行了解，在双方沟通的基础上更好的掌握学生的学习情况。
                同时，知行拥有课程记录回放功能，家长可在学习软件上回看详细的课程授课情况，对学生状态和老师
                授课质量进行监督。`,
                txtPE4: `在线上的课堂时间结束后，一般老师都会对本节课所学内容布置作业，以供学生练习，巩固本堂课的学习
                内容；之后，学生在学习软件上传作业，老师也会对学生作业进行认真的批改，并对作业中存在的问题
                进行反馈和讲解。
                而针对于有考试需求的学生，我们还会额外提供拓展性的习题作业、以及专门的考点题库，帮助学生更好
                的应对考试要求。`,
                txtPE5: `在成功注册知行账号之后，可扫码添加我们的客服人员（微信），客服人员会结合学生需求，为您匹配
                合适的老师，并为您安排好试听课程。
                您也可以点击“免费试听”，留下联系方式，随后我们的客服人员会跟您取得联系。`,
                txtPE6: `首先，有对课程设置的简要介绍，之后，会有教学内容的试讲环节。
                试听的过程，同时也是老师掌握学生基础水平的过程。老师会以之作参考，来展开之后的课程，也会对
                学生未来的中文学习提出具体的建议。`,
                txtPE7: `可以的。
                若您在学生学习过程中发现老师有不称职的地方，或者老师与学生难以成功磨合的情况，是随时可以提
                出更换老师的要求的。
                同时，考虑到课程体系的完整和连贯性，若学生能够长期跟着一个老师稳定的学习，则无疑是最理想的。
                所以，也请您对我们的老师保持一定的耐心，以及必要的信任。`,
                txtPE8: `我们的课程每堂约20美元左右（55分钟），具体根据课程种类的不同而略有区别。详细价格信息您可以
                注册后在会员中心查看，或询问我们的客服人员了解。`,
                txtPE9: `我们支持信用卡付款，包括Visa、Master card卡；Payoneer；微信；支付宝；银行间汇款等多种支付方式
                付款。
                `,
                txtPE10: `我们支持使用美元、人民币、新加坡币、欧元等币种进行付款。
                `,
                txtPE11: `首次上课前，请按照《知行中文教学系统用户指南》的指引，在智能设备上安装学习软件（为保证最佳的
                课程质量，建议尽量不要使用网页方式进入课堂）。
                安装好学习软件后按照软件指引测试摄像头、耳机、麦克风等设备的工作情况；
                在课程开始前，提前5-10分钟进入在线教室，保证课程的正常进行：
                `,
                txtPE12: `Windows/Mac电脑，apple/Android智能设备均可安装。`,
                txtPE13: `建议您采用实体网线的连接方式，或保证10M/S以上的无线连接速率。 
                学生上课期间尽量避免大流量的网络负荷，以保证课堂质量不受干扰。`,
                txtPE14: `您可以联系客服人员获取密码，您也可以在会员页面重新设置您的密码。 `,
            },
            Footer: {
                follow: "关注知行",
                ztit1: "中文课程",
                ztit2: "中文考试",
                ztit3: "常见问题",
                ztit4: "预约试听",
                ytit1: "知行师资",
                ytit2: "知行优势",
                ytit3: "考试信息",
                ytit4: "拓展阅读",
            },
            yybutton: "立刻预约  免费试听",
            Course: {
                tit1: "对标国内语文教材，主题式教学夯实中文基础。",
                titPE1: `
                &nbsp; &nbsp; &nbsp; &nbsp;对标国内《部编版》语文教材，主题式教学<br/>
                全面夯实中文基础。<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;通过生动形象的教学主题，引导学生沉浸式<br/>
                感知，启发学生主动思考，发散思维，帮助学生<br/>
                对知识点学懂吃透，融会贯通。
                `,
                tit2: "通过生动形象的教学主题，引导学生沉浸式感知，启发学生主动思考，发散思维，帮助学生对知识点学懂吃透，融会贯通。",
                ctit1: "针对海外中文家庭学生打造，系统教学，全面夯实中文基础能力。",
                ctitPE1:`
                &nbsp; &nbsp; &nbsp; &nbsp;针对海外中文家庭学生打造，系统教学，全<br/>
                面夯实中文基础能力。<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;以启发性和趣味性为基础，全面系统地教授<br/>
                基础中文知识，覆盖生活、学习中的各类语言场<br/>
                景，为将来的中文学习（IB、AP、A-Level、<br/>
                O-level、GCSE等）打下牢固基础。
                `,
                ctit2: "以启发性和趣味性为基础，全面系统地教授基础中文知识，覆盖生活、学习中的各类语言场景，为将来的中文学习",
                ctit3: " （HSK、O-level、A-level、AP、IB等）打下牢固基础。",
                ctit4: "紧抓考点，主题式教学，拓展补充关联性知识点。",
                
                ctitPE4: `
                &nbsp; &nbsp; &nbsp; &nbsp;紧抓考点，主题式教学，拓展补充关联性知<br/>识点。<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;通过经典的语料场景设计、边际拓展式教学<br/>
                ，帮助学生在主题语境中串联知识点，学懂吃透，<br/>
                融会贯通。
                `,
                ctitPE4two: `
                &nbsp; &nbsp; &nbsp; &nbsp;知行助力，为梦想插上翅膀。<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;通过参加O-level、AP、IB、中文考试，可获<br/>
                得更丰富的择校机会；提前修得学分，争取奖学<br/>
                金；增加学分绩点，提高录取几率，实现欧美名<br/>
                `,
                ctitPE4three: `
                &nbsp; &nbsp; &nbsp; &nbsp;通过HSK考试，分享中国发展机遇。<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;无论是来中国工作（入职/晋升），留学<br/>
                （入学/学分/奖学金），或长居（签证/人才认定）<br/>
                ，HSK都能精准助攻，助你一臂之力。<br/>
                `,
                ctit5: "通过经典的语料场景设计，边际拓展式教学，帮助学生在主题语境中串联知识点，学懂吃透，融会贯通。",
            },
            Lear: {
                but1: "沉浸式语言环境",
                but2: "边际拓展式提升",
                but3: "加强文化背景介绍",
                but4: "映射式联动记忆",
                tit: "“四维学习理论”",
                titPE: "“四维学习理论”",
                tit1: `知行教研组在“二语习得”论（Second Language Acquisition，
                简称SLA）基础上，结合中文自身特点，总结出一套更适合中文教学
                的“四维学习理论”。
                `,
                txt1: `
                <p>
    1、&nbsp; 沉浸式语言环境：通过模仿婴儿获得语言能力的过程，打造一个沉浸式的
</p>
<p>
    语言环境——知行着重学生“语感”的培养和形成，强化肢体语言和故事背景
</p>
<p>
    的运用，来帮助学生准确地理解词汇的意思。
</p>
                `,
                txtPE1: `
    1、&nbsp; 沉浸式语言环境：通过模仿婴儿获得语言能力的过程，打造一个沉浸式的
    语言环境——知行着重学生“语感”的培养和形成，强化肢体语言和故事背景
    的运用，来帮助学生准确地理解词汇的意思。
                `,
                txt2: `
                <p>
    2、&nbsp; 边际拓展式提升：克拉申的监控理论(Monitor Theory) 认为 ,学习者是通
</p>
<p>
    过对语言输入的理解上，来逐步强化语言学习效果的。这点恰恰与中文特点非
</p>
<p>
    常契合。
</p>
<p>
    &nbsp; &nbsp; &nbsp; &nbsp; 汉字的形成对应着人的认知思维，从具象到抽象，逐步演化和复杂化（象
</p>
<p>
    形字、指事字、会意字、形声字、假借字、转注字），环环相扣、前后相依。
</p>
<p>
    针对其特点，知行的教学方式也更强调讲授字词之间的发展和演化逻辑，在理
</p>
<p>
    解的基础上发散式学习，不建议学生使用死记硬背的方式来学习中文。
</p>
                `,
                txtPE2: `
    2、&nbsp; 边际拓展式提升：克拉申的监控理论(Monitor Theory) 认为 ,学习者是通
    过对语言输入的理解上，来逐步强化语言学习效果的。这点恰恰与中文特点非
    常契合。<br/>
    &nbsp; &nbsp; &nbsp; &nbsp; 汉字的形成对应着人的认知思维，从具象到抽象，逐步演化和复杂化（象
    形字、指事字、会意字、形声字、假借字、转注字），环环相扣、前后相依。
    针对其特点，知行的教学方式也更强调讲授字词之间的发展和演化逻辑，在理
    解的基础上发散式学习，不建议学生使用死记硬背的方式来学习中文。
                `,
                txt3: `
                <p>
    3、&nbsp; 加强文化背景介绍：语言环境理论认为，第二语言的学习也是“文化认知”
</p>
<p>
    的一个过程 ,学习者向目的语迁移的程度决定了他们第二语言学习的程度。
</p>
<p>
    &nbsp; &nbsp; &nbsp; &nbsp; 事实上，中文学习中最困难的部分恰恰就在于那些建立在文化观念上的词
</p>
<p>
    汇和意向的理解——例如“面子”、“江湖”、“上火”、“风水”等词汇，
</p>
<p>
    如果没有东方文化的认知作基础，是难以准确把握其意义的。
</p>
                `,
                txtPE3: `
    3、&nbsp; 加强文化背景介绍：语言环境理论认为，第二语言的学习也是“文化认知”
    的一个过程 ,学习者向目的语迁移的程度决定了他们第二语言学习的程度。<br/>
    &nbsp; &nbsp; &nbsp; &nbsp; 事实上，中文学习中最困难的部分恰恰就在于那些建立在文化观念上的词
    汇和意向的理解——例如“面子”、“江湖”、“上火”、“风水”等词汇，
    如果没有东方文化的认知作基础，是难以准确把握其意义的。
                `,
                txt4: `
                <p>
    4、&nbsp; 映射式联动记忆：人类学习语言的黄金时期是2-4岁这个阶段，此时脑
</p>
<p>
    部“布罗卡区”（Broca s area）快速发育，错过这个时间段，大脑难以把
</p>
<p>
    外语直接贮存在布罗卡区，而是转为存储在记忆区。此时，通过词汇意向的
</p>
<p>
    转化关系来强化这两个区域间的联系，就显得格外重要。
</p>
<p>
    &nbsp; &nbsp; &nbsp; &nbsp; 因此，知行的课程体系非常重视海外学生的华语环境，也倾向于用他们
</p>
<p>
    习惯的语言和生活环境来解释中文词汇，以此来加强两个脑部区域之间的
</p>
<p>
    联动关系，巩固第二语言的学习效果。
</p>
                `,
                txtPE4: `
    4、&nbsp; 映射式联动记忆：人类学习语言的黄金时期是2-4岁这个阶段，此时脑
    部“布罗卡区”（Broca s area）快速发育，错过这个时间段，大脑难以把
    外语直接贮存在布罗卡区，而是转为存储在记忆区。此时，通过词汇意向的
    转化关系来强化这两个区域间的联系，就显得格外重要。<br/>
    &nbsp; &nbsp; &nbsp; &nbsp; 因此，知行的课程体系非常重视海外学生的华语环境，也倾向于用他们
    习惯的语言和生活环境来解释中文词汇，以此来加强两个脑部区域之间的
    联动关系，巩固第二语言的学习效果。
                `,
            },
            HCourse: {
                ctit1: "结合经典语料主题，全面拓展中文知识储备。",
                ctitPE1: `
                &nbsp; &nbsp; &nbsp; &nbsp;结合经典语料主题，全面拓展中文知识。<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;通过经典的语料场景设计，边际拓展式教学<br/>
                帮助学生在主题语境中串联知识点，学懂吃透，<br/>
                融会贯通。
                `,
                ctit2: "通过经典的语料场景设计，边际拓展式教学，帮助学生在主题语境中串联知识点，学懂吃透，融会贯通。",
                txt1: `
                <p class="phg">
                &nbsp;&nbsp; &nbsp; &nbsp; 顶级师资、高屋建瓴
            </p>
            <div  style="line-height: 0.14rem;">
            <p>
             &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;知行授课教师全部来自于教育
            </p>
            <p>
                &nbsp;&nbsp;部直属6大师范院校，汉语言文学专
            </p>
            <p>
                &nbsp;&nbsp;业，80%以上老师拥有211硕士以上
            </p>
            <p>
                &nbsp;&nbsp;学历，100%教师拥有国际中文教师
            </p>
            <p>
                &nbsp;&nbsp;证书/教师资格证（语文科目）。
            </p>
            <p>
             
             &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;知行教研专家针对HSK考试，
            </p>
            <p>
                &nbsp;&nbsp;有针对性地加强难点、重点，帮助
            </p>
            <p>
                &nbsp;&nbsp;学生高效通过考试。
            </p>
            </div>
                `,txtPE1: `
                <p class="tit">
                顶级师资、高屋建瓴
            </p>
            <div class="txt">
            <p>
            知行授课教师全部来自于教育部直属6大
            </p>
            <p>
            师范院校、汉语言文学专业，80%以上老师
            </p>
            <p>
            拥有211硕士以上学历，100%教师拥有国际
            </p>
            <p>
            中文教师证书/教师资格证（语文）。
            </p>
            <p>
            知行教研专家针对HSK考试，有针对性
            </p>
            <p>
            地加强重、难点，帮助学生高效通过考试。
            </p>
            </div>
                `,
                txt2: `

                <p class="phg">&nbsp; &nbsp; &nbsp; &nbsp;模块分类、个性系统</p>
        <div >
        <p>
        &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
            知行根据HSK考试特点，将重要
            </p>
            <p>
            &nbsp;知识点、高频考点进行归集分类，形
            </p>
            <p>
            &nbsp;成主题式教学模块，以适应不同基础
            </p>
            <p>
            &nbsp;、不同需求的考生。无论学生基础如
            </p>
            <p>
            &nbsp;何，都能方便迅速地补齐薄弱和缺漏。
        </p>
        <p>
        &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
            通过灵活调用这套模块知识体系,
            </p>
            <p>
            &nbsp;课程的个性化和系统化得以兼顾，在
            </p>
            <p>
            &nbsp;提高潜力分数的同时保证学习效率。
            
        </p>
        </div>
                `,
                txtPE2: `
                <p class="tit">模块分类、个性系统</p>
            <div class="txt">
                <p>知行根据选拔考试特点，将重要知识点、
                </p>
                <p>
                高频考点归集分类，形成主题式教学模块，
                </p>
                <p>
                适应不同基础、不同需求的考生。无论学生
                </p>
                <p>
                基础如何，都能方便迅速地补齐薄弱/缺漏。
                </p>
                <p>
                通过灵活调用这套模块知识体系，课程
                </p>
                <p>
                的个性化和系统化得以兼顾，在提高潜力分
                </p>
                <p>
                数的同时保证学习效率。
                </p>
            </div>
                `,
                txt21: `

                <p class="phg">&nbsp; &nbsp; &nbsp; &nbsp;模块分类、个性系统</p>
        <div >
        <p>
        &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
            知行根据选拔考试特点，将重要
            </p>
            <p>
            &nbsp;知识点、高频考点进行归集分类，形
            </p>
            <p>
            &nbsp;成主题式教学模块，以适应不同基础
            </p>
            <p>
            &nbsp;、不同需求的考生。无论学生基础如
            </p>
            <p>
            &nbsp;何，都能方便迅速地补齐薄弱和缺漏。
        </p>
        <p>
        &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
            通过灵活调用这套模块知识体系,
            </p>
            <p>
            &nbsp;课程的个性化和系统化得以兼顾，在
            </p>
            <p>
            &nbsp;提高潜力分数的同时保证学习效率。
            
        </p>
        </div>
                `,
                txt3: `
                <p class="phg">&nbsp; &nbsp; &nbsp; &nbsp;启发教学、融会贯通</p>
        
                <p>
        &nbsp; &nbsp; &nbsp;&nbsp;
        &nbsp;知行课堂通过深入浅出的教学方
            </p>
            <p>
            &nbsp;法，启发学生主动思考，发散思维，
            </p>
            <p>
            &nbsp;将生涩难懂的知识点以生动有趣的方
            </p>
            <p>
            &nbsp;式传授给学生，结合经典的文化故事
            </p>
            <p>
            &nbsp;和叙事场景，帮助学生对知识点学以
            </p>
            <p>
            &nbsp;致用，融会贯通。
        </p>
        <p>
        &nbsp; &nbsp; &nbsp;&nbsp;
        &nbsp;通过激发学生优势板块的潜力，
            </p>
            <p>
            &nbsp;增强学生兴趣，从而补齐弱势短板。
        </p>
                `,
                txtPE3: `
                <p class="tit">启发教学、融会贯通</p>
            <div class="txt">
                <p>
                知行课堂通过深入浅出的教学方法，启
                </p>
                <p>
                发学生主动思考，发散思维，将生涩难懂的
                </p>
                <p>
                知识点以生动有趣的方式传授给学生，结合
                </p>
                <p>
                经典的文化故事和叙事场景，帮助学生对知
                </p>
                <p>识点学以致用，融会贯通。
                </p>
                <p>
                通过激发学生优势板块的潜力，增强
                </p>
                <p>
                学生兴趣，从而补齐弱势短板。
                </p>
            </div>
                `,
                txt4: `
        <p class="phg">&nbsp; &nbsp&nbsp; &nbsp; &nbsp;应试训练、名师押题</p>
        <p>
        &nbsp; &nbsp;&nbsp;
            &nbsp;
            &nbsp; &nbsp知行针对高频考点和得分点，
            </p>
            <p>
            &nbsp; &nbsp形成题库，强化模拟和练习；根据
            </p>
            <p>
            &nbsp; &nbsp学生特点定制得分策略，并提供模
            </p>
            <p>
            &nbsp; &nbsp拟试题，帮助学生适应考试。
            
        </p>
        <p>
        &nbsp; &nbsp&nbsp; &nbsp;&nbsp;
            &nbsp;
            知行教研组针对考试大纲，结
            </p>
            <p>
            &nbsp; &nbsp合近年考点轮动特点，研发押题卷
            </p>
            <p>
            &nbsp; &nbsp，帮助学生在考前抓住潜力分值，
            </p>
            <p>
            &nbsp; &nbsp高效通过考试。
            
        </p>
                `,
                txtPE4: `
                <p class="tit">应试训练、名师押题</p>
            <div class="txt">
                <p>
                知行针对高频考点和得分点，形成题库，
                </p>
                <p>
                强化模拟和练习；根据学生特点定制得分策
                </p>
                <p>
                略，并提供模拟试题，帮助学生适应考试。
                </p>
                <p>
                知行教研组针对考试大纲，结合近年考
                </p>
                <p>点轮动特点，研发押题卷，帮助学生在考前
                </p>
                <p>
                抓住潜力分值，高效通过考试。
                </p>
            </div>
                `,
                atit1: "顶级师资",
                atit11: "顶级师资",
                atit2: "直击考点",
                atit3: "高分训练",
                atit4: "备考服务",
                atit5: "学生为本",
                atit6: "跟踪服务",
                atxt1: "一线专业教师",
                atxt11: "知识体系搭建",
                atxt12: "六大师范院校",
                atxt13: "顶级师资授课",
                atxt2: "历年真题分析",
                atxt21: "针对考点教研",
                atxt3: "经典题库练习",
                atxt31: "高分技巧训练",
                atxt4: "名师高分押题",
                atxt41: "考前模拟训练",

                atxt5: "兴趣启发教学",
                atxt51: "成绩提升承诺",
                atxt511: "成绩提升承诺",

                atxt6: "教管跟踪服务",
                atxt61: "无忧退款承诺",

                dtit1: "通过HSK考试，分享中国发展机遇。",
                dtit2: "无论是来中国工作（入职/晋升），留学（入学/学分/奖学金），或长居（签证/人才认定），HSK都能精准助攻，助你一臂之力。",
                dtxt1: "知名企业入职、晋升优势",
                dtxt2: "中国名校留学机会",
                dtxt3: "外国人留华工作签证",
                dtit3: "知行助力，为梦想插上翅膀。",
                dtit4: "通过参加O-level、AP、IB、中文考试，可获得更丰富的择校机会；提前修得学分，争取奖学金；增加学分绩点，提高录取几率，实现欧美名校梦想。",
                dtxt4: "进入欧美名校",
                dtxt5: "积累学分",
                dtxt6: "获得更丰富的择校机会",
            },
            Olevel: {
                tit1: "O-LEVEL中文考试",
                tit2: "AP中文考试",
                tit3: "IB中文考试",
            },
            Advantage: {
                tit1: "学员为本",
                tit2: "启发教学",
                tit3: "学考一体",
                tit4: "顶级师资",
                tit5: "系统衔接",
                otit1: `
                <p>知行的教研及授课体系都是以学生的角度</p>
                <p>为出发点，来进行设计和布置，引导学生</p>
                <p>用自己的思维去理解消化知识内容。</p>
                `,
                otitPE1: `
                知行的教研及授课体系都是以学生的角度</br>
                为出发点，来进行设计和布置，引导学生</br>
                用自己的思维去理解消化知识内容。</br>
                `,
                otit2: `
                <p>知行结合汉语特点，将不同级别的知识</p>
                <p>点模块化、系统化，以适应不同基础、不</p>
                <p>同需求的学生。无论学生从哪个级别开始</p>
                <p>学习，都能方便迅速地补齐薄弱和缺漏。</p>
                `,
                otitPE2: `
                知行结合汉语特点，将不同级别的知识</br>
                点模块化、系统化，以适应不同基础、不</br>
                同需求的学生。无论学生从哪个级别开始</br>
                学习，都能方便迅速地补齐薄弱和缺漏。</br>
                `,
                otit3: `
                <p>知行重视学生本身的语言基础和特点，强</p>
                <p>调从学生的个性出发，去融入共性的知识</p>
                <p>内容；同时结合海外华文的应用和生活场</p>
                <p>景，回归地道的汉语用法和规范。</p>
                `,
                otitPE3: `
                知行重视学生本身的语言基础和特点，强</br>
                调从学生的个性出发，去融入共性的知识</br>
                内容；同时结合海外华文的应用和生活场</br>
                景，回归地道的汉语用法和规范。</br>
                `,
                ftit: `
                <p>
      &nbsp; &nbsp; &nbsp; &nbsp;
      知行授课教师全部来自教育部直属6大师范院校，汉语言文学专业，3年以上一线教学经验；80%以上老师
      拥有211硕士以上学历，100%教师拥有国际中文教师证书/教师资格证（语文科目）；知行教研组与西南大学
      、华中师范大学文学院合作，聘请高校专家教授，为知行定制课程教研。
    </p>
    <p style="margin-top:0.05rem;">
      &nbsp; &nbsp; &nbsp; &nbsp;
      在知行，70%的教师拥有海外游学/派遣经历，30%教师拥有公立学校任教经历。知行渴望并重视这些教学底蕴的养成，会定期对教师队伍进行相关主题的分享和培训，确保教师拥有多元复合的教学能力。
    </p>
                `,
                ftitPE: `
                <p>
      &nbsp; &nbsp; &nbsp; &nbsp;
      知行授课教师全部来自教育部直属6大师范院校，汉语言文学专业，3年以上一线教学经验；80%以上老师
      拥有211硕士以上学历，100%教师拥有国际中文教师证书/教师资格证（语文科目）；知行教研组与西南大学
      、华中师范大学文学院合作，聘请高校专家教授，为知行定制课程教研。
    </p>
    <p style="margin-top:0.05rem;">
      &nbsp; &nbsp; &nbsp; &nbsp;
      在知行，70%的教师拥有海外游学/派遣经历，30%教师拥有公立学校任教经历。知行渴望并重视这些教学底蕴的养成，会定期对教师队伍进行相关主题的分享和培训，确保教师拥有多元复合的教学能力。
    </p>
                `,
                ttit1: "站在学生的角度设计教学，引导学生学习。",
                ttitPE1: `
                &nbsp; &nbsp; &nbsp; &nbsp;站在学生的角度设计教学，引导学生学习。<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;通过深入浅出的教学方法，启发学生主动思<br/>
                考，发散思维，结合经典的文化故事和叙事场<br/>
                景，帮助学生对知识点学以致用，融会贯通。<br/>
                `,
                ttit2: "通过深入浅出的教学方法，启发学生主动思考，发散思维，结合经典的文化故事和叙事场景，帮助学生对知识点学以致用，融会贯通。",
                ttit3: "",
                ftit2: `
                <p>
                &nbsp; &nbsp; &nbsp; &nbsp;
                知行针对中文教学中的重要知识点、高频考点进行归集分类，形成主题式教学模块，以适应不同基础、不同目标的学生需求。无论学生从哪个级别开始课程，通过个性调用这套模块化课件库，都能方便迅速地补齐薄弱和缺漏，快速适应学/考要求。
              </p>
              <p style="margin-top:0.05rem;">
                &nbsp; &nbsp; &nbsp; &nbsp;
                借助于这套高度灵活的模块知识体系，课程的个性化和系统化得以兼顾，在提高学生潜力分数的同时保证学习的效率。无论是哪个板块的知识内容，知行都能保证学习成果自成体系，完备自如。
              </p>
                `,
                gtit: "知行助力，高分通过考试",
                gtitPE: "知行助力，高分通过考试",
            },
            Further: {
                tit1: "以热忱和专业，帮助每一位知行学员，在中文学习过程中，学有所成、不负韶华。",
                titpe1: `以热忱和专业，帮助每一位知行学员，<br/>
                在中文学习过程中，学有所成、不负韶华。`,
                tit2: "汇聚最专业的中文师资队伍，打造最优质的中文教学资源",
                titPE2: `
                汇聚最专业的中文师资队伍<br/>
                打造最优质的中文教学资源`,
                txt1: "知行坚持以优质的师资队伍，学员为本的教学理念，启发性的教学方法，系统衔接的教学体系，学考一体的教学策略，为海外中文学生提供教学服务。",
                txt2: "无论您身处全球何处，都能享受到一堂优质的中文课程。",
                txt3: "",
                ftit: "以优质的师资保证教学质量",
                ftxt1: `
                <p>
            知行教师全部来自<span style="color: rgb(255, 0, 0)"
              >教育部直属6大师范院校</span
            >，
          </p>
          <p>汉语言文学专业，3年以上一线教学经验；</p>
          <p>
            <span style="color: rgb(255, 0, 0)">100%以上老师拥有211硕士</span>以上学历。
          </p>
                `,
                ftxtPE1: `
                <p>
            知行教师全部来自<span style="color: rgb(255, 0, 0)"
              >教育部直属6大师范</span
            >院校，
          </p>
          <p>汉语言文学专业，3年以上一线教学经验；</p>
          <p>
            <span style="color: rgb(255, 0, 0)">100%</span>老师拥有<span style="color: rgb(255, 0, 0)">211硕士</span>以上学历。
          </p>
                `,
                ftxt2: `
                <p>知行与西南大学、华中师范大学文学院合作，</p>
          <p>
            <span style="color: rgb(255, 0, 0)">聘请高校专家教授</span
            >，为知行定制课程教研。
          </p>
                `,
                ftxtPE2: `
                <p>知行与西南大学、华中师范大学文学院合作，</p>
          <p>
          聘请<span style="color: rgb(255, 0, 0)">高校专家教授</span
            >，为知行定制课程教研。
          </p>
                `,
                ftxt3: `
                <p>
            知行坚持以<span style="color: rgb(255, 0, 0)">任职教师内部推荐</span
            >的方式招聘新
          </p>
          <p>
            教师，<span style="color: rgb(255, 0, 0)">拒绝社会招聘</span
            >，以此保证教师团队的
          </p>
          <p>高质量。</p>
                `,
                ftxtPE3: `
                <p>
            知行坚持以<span style="color: rgb(255, 0, 0)">任职教师内部推荐</span
            >的方式招聘新
          </p>
          <p>
            教师，<span style="color: rgb(255, 0, 0)">拒绝社会招聘</span
            >，以此保证教师团队的
          </p>
          <p>高质量。</p>
                `,
                ftxt4: `
                <p>
            在知行，70%的教师拥有<span style="color: rgb(255, 0, 0)">海外游学/派遣</span
            >经历
          </p>
          <p>
            ，30%教师拥有<span style="color: rgb(255, 0, 0)">公立学校任教</span
            >经历。知行渴
          </p>
          <p>望并重视这些教学底蕴的养成，会定期对教</p>
          <p>师队伍进行相关主题的分享和培训，确保教</p>
          <p>师拥有<span style="color: rgb(255, 0, 0)">多元复合</span>的教学能力。</p>
                `,
                ftxtPE4: `
                <p>
            在知行，<span style="color: rgb(255, 0, 0)">70%</span>的教师拥有<span style="color: rgb(255, 0, 0)">海外游学/派遣</span
            >经历
          </p>
          <p>
            ，<span style="color: rgb(255, 0, 0)">30%</span>教师拥有<span style="color: rgb(255, 0, 0)">公立学校任教</span
            >经历。知行渴
          </p>
          <p>望并重视这些教学底蕴的养成，会定期对教</p>
          <p>师队伍进行相关主题的分享和培训，确保教</p>
          <p>师拥有<span style="color: rgb(255, 0, 0)">多元复合</span>的教学能力。</p>
                `,
                ttit: `
      <p>
&nbsp; &nbsp; &nbsp; &nbsp;
知行针对中文教学中的重要知识点、基础考点进行归集分类，形成主题式教学模块，以适应不同基础、不同目标的学生需求。无论学生从哪个级别开始课程，通过灵活调用这套模块化课件库，都能方便、迅速地补齐薄弱和缺漏，在提高潜力分数的同时保证学习效率。在课程的体验上，实现个性化和系统化的兼顾。
</p>
<p style="margin-top:0.05rem;">
&nbsp; &nbsp; &nbsp; &nbsp;
主题式的教学形式便于更好地引导学生沉浸式感知，启发学生主动思考，发散思维，帮助学生对知识点学懂吃透，融会贯通。
</p>
                `,
                ttitPE: `
                <p>
      &nbsp; &nbsp; &nbsp;&nbsp;
      知行针对中文教学中的重要知识点、基础考</p>
      <p>点进行归集分类，形成主题式教学模块，以适应</p>
      <p>不同基础、不同目标的学生需求。无论学生从哪</p>
      <p>个级别开始课程，通过灵活调用这套模块化课件</p>
      <p>库，都能方便、迅速地补齐薄弱和缺漏，在提高</p>
      <p>潜力分数的同时保证学习效率。在课程的体验</p>
      上，实现个性化和系统化的兼顾。
    
    <p style="margin-top:0.05rem;">
      &nbsp; &nbsp; &nbsp;&nbsp;
      主题式的教学形式便于更好地引导学生沉浸</p>
      <p>式感知，启发学生主动思考，发散思维，帮助学</p>
      生对知识点学懂吃透，融会贯通。
                `,
                ttitYsPE: `
                <p>
      &nbsp; &nbsp; &nbsp;&nbsp;
      知行针对中文教学中的重要知识点、高频考</p>
      <p>点进行归集分类，形成主题式教学模块，以适应</p>
      <p>不同基础、不同目标的学生需求。无论学生从哪</p>
      <p>个级别开始课程，通过灵活调用这套模块化课件</p>
      <p>库，都能方便迅速地补齐薄弱和缺漏，快速适应</p>
      <p>学/考要求。</p>
    
    <p style="margin-top:0.05rem;">
      &nbsp; &nbsp; &nbsp;&nbsp;
      借助于这套高度灵活的模块知识体系，课程</p>
      <p>的个性化和系统化得以兼顾，在提高学生潜力分</p>
      <p>数的同时保证学习的效率。无论是哪个板块的知</p>
      <p>识内容，知行都能保证学习成果自成体系，完备</p>
      自如。
                `,
            },
            Blog: {
                type1: "考试信息",
                type2: "拓展阅读",
            },
            FreeT: {
                tit1: "顶级师资",
                tit2: "学生为本",
                tit3: "跟踪服务",
                txt1: "1对1，55分钟直播互动课",
                txt2: "配套课程录播功能，永久回看",
                txt3: "课后习题练习批改，完整学习闭环",
                txt4: "赠送家长会，定期反馈学习效果",
                iput1: "姓&nbsp;&nbsp;&nbsp;&nbsp;名",
                iput2: "邮&nbsp;&nbsp;&nbsp;&nbsp;箱",
                iput3: "社交媒体",
                iput4: "所在国家",
                iput5: "请输入姓名",
                iput6: "请输入邮箱",
                iput7: "请输入账号",
                iput8: "请输入所在国家/地区",
            },
        },
        en: {
            Typeface: {
                ztAl35: "ztInter35",
                ztAl55: "ztInter55",
                ztAl75: "ztInter75",
            },
            floor: {
                topTitle1: "Access to the First-Rate Teaching",
                topTitle2: "Resources Around the World",
                topTxt1: "1V1, 55 mins interactive class",
                topTxt2: "Course recording for replay",
                topTxt3: "Homework for the closed loop",
                topTxt4: "Free parents' meeting for feedback",
                title1: "Follow ZhiXing",
                title2: "Contact Us",
                text1: "China headquarters:",
                text2: "US companies:",
                text3: "Hong Kong Company:",
                class1: "Chinese Course",
                class2: "Chinese Exam",
                class3: "General issues",
                class4: "Book a Free Trial",
                class5: "ZhiXing Faculty",
                class6: "ZhiXing Advantage",
                class7: "Exam Information",
                class8: "Extended Reading",
            },
            FreeT: {
                iput1: "Name",
                iput2: "Email",
                iput3: "Social Media",
                iput4: "Country",
                iput5: "Enter your name",
                iput6: "Enter the email address",
                iput7: "Enter the account number",
                iput8: "Enter the country name",
                txt1: "1V1, 55 minutes live interactive class",
                txt2: "Course recording for permanent replay",
                txt3: "After-class exercises for a closed loop",
                txt4: "Free parents' meeting for regular feedback",
                tit1: "Top Faculty",
                tit2: "Student-Oriented",
                tit3: "Tracking Service",
            },
            Blog: {
                type1: "Information",
                type2: "Reading",
            },
            Further: {
                ttit: `
                <p>
    &nbsp; &nbsp; We collected important knowledge and high-frequency points into thematic teaching modules , so the students with different foundations and needs could quickly fill in the missing and weakness knowledge, and adapt to study/test requirements. The class could be both personalized and systematic by flexible using of the knowledge module base.
</p>
<p style="margin-top:0.03rem;">
    &nbsp; &nbsp; Thematic teaching method will promote students&#39; immersive perception, inspire their divergent thinking, and help them realize the application and accurate meaning of knowledge.
</p>
                `,
                ttitPE: `
                <p>&nbsp; &nbsp; We collected important knowledge and high-<p/>
                <p>frequency points into thematic teaching modules ,<p/>
                <p>so the students with different foundations and<p/>
                <p>needs could quickly fill in the missing and weakness <p/>
                <p>knowledge, and adapt to study/test requirements. <p/>
                <p>Class could be both personalized and systematic<p/>
                <p>by flexible using of the knowledge module base.<p/>
                <p style="margin-top:0.03rem;">&nbsp; &nbsp; Thematic teaching method will promote students&#39;</p>
                <p>immersive perception, inspire their divergent</p>
                <p>thinking, and help them realize the application and</p>
                accurate meaning of knowledge.
                `,
                ttitYsPE: `
                <p>&nbsp; &nbsp; We collected important knowledge and high-<p/>
                <p>frequency points into thematic teaching modules , <p/>
                <p>so the students with different foundations and needs<p/>
                <p>could quickly fill in the missing and weakness <p/>
                <p>knowledge, and adapt to study/exam requirements. <p/>
                <p style="margin-top:0.03rem;">&nbsp; &nbsp; By flexible use of this modular system, our </p>
                <p>curriculum could be both personalized and </p>
                <p>systematic, helping students improve the potential</p>
                <p>score with efficiency. No matter which board you are</p>
                <p>in, the learning outcomes could be both </p>
                self-contained and complete.
                `,
                ftxt1: `
                <p>
    Our teachers all come from <span style="color: rgb(255, 0, 0);">the six major normal&nbsp;</span>
</p>
<p>
    <span style="color: rgb(255, 0, 0);">universities</span> under the Ministry of Education,&nbsp;
</p>
<p>
    majoring in Chinese language; More than 100%&nbsp;
</p>
<p>
    have a <span style="color: rgb(255, 0, 0);">master&#39;s degree or above in 211.</span>
</p>
                `,
                ftxtPE1: `
                <p>
    Our teachers all come from <span style="color: rgb(255, 0, 0);">the six major normal </span>
</p>
<p>
    <span style="color: rgb(255, 0, 0);">universities</span> under the Ministry of Education, 
</p>
<p>
    majoring in Chinese language; 100% have a 
</p>
<p>
    <span style="color: rgb(255, 0, 0);">master&#39;s degree or above in 211.</span>
</p>
                `,
                ftxt2: `
                <p>
    Employ <span style="color: rgb(255, 0, 0);">professors from Universities</span> to customize
</p>
<p>
    &nbsp;the curriculum.
</p>
                `,
                ftxtPE2: `
                <p>
    Employ <span style="color: rgb(255, 0, 0);">professors from Universities</span> to 
</p>
<p>
customize the curriculum.
</p>
                `,
                ftxt3: `
                <p>
    Adhere to the<span style="color: rgb(255, 0, 0);"> internal recommendation</span> to recruit
</p>
<p>
    new teachers, so as to ensure a high-quality&nbsp;
</p>
<p>
    teaching team.
</p>
                `,
                ftxtPE3: `
                <p>
    Adhere to the<span style="color: rgb(255, 0, 0);"> internal recommendation</span> to 
</p>
<p>
recruit new teachers, so as to ensure a 
</p>
<p>
high-quality teaching team.
</p>
                `,
                ftxt4: `
                <p>
    70% teachers have <span style="color: rgb(255, 0, 0);">overseas study / dispatch&nbsp;</span>
</p>
<p>
    <span style="color: rgb(255, 0, 0);">experience</span>, 30% have <span style="color: rgb(255, 0, 0);">public school teaching&nbsp;</span>
</p>
<p>
    <span style="color: rgb(255, 0, 0);">experience</span>. We attach importance to these&nbsp;&nbsp;
</p>
<p>
    experience, and regularly share related topics&nbsp;
</p>
<p>
    mutually to ensure <span style="color: rgb(255, 0, 0);">Multi-Component teaching&nbsp;</span>
</p>
<p>
    <span style="color: rgb(255, 0, 0);">abilities.</span>
</p>
                `,
                ftxtPE4: `
                <p>
    70% teachers have <span style="color: rgb(255, 0, 0);">overseas study / dispatch&nbsp;</span>
</p>
<p>
    <span style="color: rgb(255, 0, 0);">experience</span>, 30% have <span style="color: rgb(255, 0, 0);">public school teaching&nbsp;</span>
</p>
<p>
    <span style="color: rgb(255, 0, 0);">experience</span>. We attach importance to these&nbsp;&nbsp;
</p>
<p>
    experience, and regularly share related topics&nbsp;
</p>
<p>
    mutually to ensure <span style="color: rgb(255, 0, 0);">Multi-Component teaching&nbsp;</span>
</p>
<p>
    <span style="color: rgb(255, 0, 0);">abilities.</span>
</p>
                `,
                ftit: "Teaching with First-rate Faculty",
                tit2: "Bring Together the Most Professional Teachers to Create the Best Teaching Resources",
                titPE2: `
                Bring Together the Most Professional Teachers<br/>
                to Create the Best Teaching Resources`,
                txt1: "ZhiXing insist on providing the best teaching services for overseas students with first-class faculty, ",
                txt2: `philosophy of "students oriented", interesting teaching, connected system and integration of learning and examination.`,
                txt3: "No matter where you are in the world, you can always get the first-rate Chinese teaching resources here.",
                tit1: "With enthusiasm and professionalism, ZhiXing help every student to get an achievement in Chinese subject.",

                titpe1: `With enthusiasm and professionalism, ZhiXing help <br/>
                every student to get an achievement in Chinese subject.`,
            },
            Advantage: {
                gtit: "ZhiXing Supports to Pass the Exam with High Marks",
                gtitPE: `
                ZhiXing Supports to<br/>
                Pass the Exam with High Marks
                `,
                ftit2: `
                <p>
    &nbsp; &nbsp; We collected important knowledge and high-frequency points into thematic teaching modules , so the students with different foundations and needs could quickly fill in the missing and weakness knowledge, and adapt to study/exam requirements.
</p>
<p style="margin-top:0.05rem;">
    &nbsp; &nbsp; By flexible use of this modular system, our curriculum could be both personalized and systematic, helping students improve the potential score with efficiency. No matter which board you are in, the learning outcomes could be both self-contained and complete.
</p>
                `,
                ttit1: "Design our curriculum from the perspective of students, guide them to learn with their mind.",
                ttitPE1: `
                &nbsp; &nbsp; Design our curriculum from the perspective of <br/>
                students, guide them to learn with their mind.<br/>
                &nbsp; &nbsp; Inspire students' divergent and active thinking by<br/>
                using an in-depth teaching style. Combine classic<br/>
                cultural stories and narrative scenes to realize the<br/>
                application and accurate meaning of knowledge.
                `,
                ttit2: "Inspire students' divergent and active thinking by using an in-depth teaching style. Combine classic cultural",
                ttit3: "stories and narrative scenes to realize the application and accurate meaning of knowledge.",
                ftit: `
                <p>
    &nbsp; &nbsp; Our teachers all come from the six major normal universities directly under the Ministry of Education, majoring in Chinese language and literature,&nbsp;
</p>
<p>
    with more than 3 years of front-line teaching experience. All teachers have International Chinese Teacher Certificates/Teacher Qualification Certificates&nbsp;
</p>
<p>
    (Chinese subject), more than 80% of them have a master&#39;s degree or above in 211. In cooperation with College of Arts of Southwest University and&nbsp;
</p>
<p>
    Huazhong Normal University, ZhiXing teaching and research group employ professors from universities to customize the curriculum.
</p>
<p style="margin-top:0.05rem;">
    &nbsp; &nbsp; 70% of our teachers have overseas study /dispatch experience, and 30% have public school teaching experience. We attach importance to these&nbsp;
</p>
<p>
    teaching experience, and will regularly share those related topics mutually to ensure Multi-Component teaching abilities.
</p>
                `,
                ftitPE: `
        &nbsp; &nbsp; Our teachers all come from the six major normal<br/>
        universities directly under the Ministry of Education,<br/>
        majoring in Chinese language and literature, with<br/>
        more than 3 years of front-line teaching experience.<br/>
        All teachers have International Chinese Teacher<br/>
        Certificates/Teacher Qualification Certificates<br/>
        (Chinese subject), more than 80% of them have a<br/>
        master's degree or above in 211. In cooperation with<br/>
        College of Arts of Southwest University and<br/>
        Huazhong Normal University, ZhiXing teaching and<br/>
        research group employ professors from universities<br/>
        to customize the curriculum.<br/>
        <p style="margin-top:0.05rem;">
         &nbsp; &nbsp; 70% of our teachers have overseas study /dispatch</p>
         experience, and 30% have public school teaching<br/>
         experience. We attach importance to these teaching<br/>
         experience, and will regularly share those related<br/>
         topics mutually to ensure Multi-Component teaching<br/>
         abilities.

                `,
                otit1: `
                <p>The research and teaching system are</p>
                <p>designed based on the perspective of</p>
                <p>students, guiding them to master the</p>
                <p>knowledge with their own mind.</p>
                `,
                otitPE1: `
                The research and teaching system are</br>
                designed based on the perspective of</br>
                students, guiding them to master the</br>
                knowledge with their own mind.</br>
                `,
                otit2: `
                <p>Combining with the characteristics of</p>
                <p>Chinese, the courses of different levels are</p>
                <p>modularized and systematized to adapt to</p>
                <p>students with different foundations, so they</p>
                <p>can quickly fill in the weak and missing.</p>
                `,
                otitPE2: `
                Combining with the characteristics of</br>
                Chinese, the courses of different levels are</br>
                modularized and systematized to adapt to</br>
                students with different foundations, so they</br>
                can quickly fill in the weak and missing.</br>
                `,
                otit3: `
                <p>Pay attention to students' foundation and</p>
                <p>characteristics, teaching common content</p>
                <p>from personality, guide them from a point to</p>
                <p>the surface; Teaching normative Chinese</p>
                <p>based on daily life and study.</p>
                `,
                otitPE3: `
                Pay attention to students' foundation and</br>
                characteristics, teaching common content</br>
                from personality, guide them from a point</br>
                to the surface; Teaching normative</br>
                Chinese based on daily life and study.</br>
                `,
                tit1: "Student-Oriented",
                tit2: "Inspiring Teaching",
                tit3: "Learning-Examination",
                tit4: "First-class Faculty",
                tit5: "Systematic Connection",
            },
            Olevel: {
                tit1: "O-LEVEL Chinese Course",
                tit2: "AP Chinese Course",
                tit3: "IB Chinese Course",
            },
            HCourse: {
                dtxt4: "Famous schools in Europe and America",
                dtxt5: "Accumulated credits",
                dtxt6: "More opportunities for school selection",
                dtxt1: "Job opportunities and promotion",
                dtxt2: "Opportunities for studying in China",
                dtxt3: "Work visa for foreigners in China",
                dtit1: "Share China's development opportunities through HSK exam.",
                dtit2: "The HSK exam could help you work(entry/promotion), study (admission/credit/scholarship), or stay (visa/talent recognition) in China.",
                atxt1: "Six major normal Universities",
                atxt11: "Customized teaching service",
                atxt12: "Six major normal Universities",
                atxt13: "Customized teaching service",
                dtit3: "ZhiXing helps to make dreams come ture.",
                dtit4: "Get more opportunities to choose schools; Obtain credits in advance and strive for scholarships; Increase GPA, and realize the dream of famous universities.",
                atxt2: "Analysis of past exams",
                atxt21: "Teaching around exams",
                atxt3: "Classic question practice",
                atxt31: "High-score skills training",
                atxt4: "Underline the key points",
                atxt41: "Pre-test simulated training",

                atxt5: "Inspired teaching with interest",
                atxt51: "Commitment to score improvement",
                atxt511: "Commitment to score improve",

                atxt6: "Professional follow-up service",
                atxt61: "Refund promise with no worry",

                atit1: "First-Rate Faculties",
                
                atit11: "Top Faculty",
                atit2: "Aim to Test Points",
                atit3: "High Score Training",
                atit4: "Exam Preparation",
                atit5: "Student-Oriented",
                atit6: "Tracking Service",
                txt1: `
                <p class="phgzn">
                First-class Faculties
            </p>
            <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our teachers all come from the six&nbsp;
            </p>
            <p>
            &nbsp;&nbsp;&nbsp;major normal universities majoring in&nbsp;
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;Chinese, all have International Chinese&nbsp;
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;Certificates/Qualification Certificates,&nbsp;
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;more than 80% have a master&#39;sdegree&nbsp;
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;or above in 211.
            </p>
            <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; ZhiXing strengthen the difficult and&nbsp;
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;key points aiming at the HSK exam, to
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;help students pass the exam efficiently.
            </p>
                `,
                txt1: `
                <p class="phg">
                &nbsp;&nbsp; &nbsp; &nbsp; 顶级师资、高屋建瓴
            </p>
            <div  style="line-height: 0.14rem;">
            <p>
             &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;知行授课教师全部来自于教育
            </p>
            <p>
                &nbsp;&nbsp;部直属6大师范院校，汉语言文学专
            </p>
            <p>
                &nbsp;&nbsp;业，80%以上老师拥有211硕士以上
            </p>
            <p>
                &nbsp;&nbsp;学历，100%教师拥有国际中文教师
            </p>
            <p>
                &nbsp;&nbsp;证书/教师资格证（语文科目）。
            </p>
            <p>
             
             &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;知行教研专家针对HSK考试，
            </p>
            <p>
                &nbsp;&nbsp;有针对性地加强难点、重点，帮助
            </p>
            <p>
                &nbsp;&nbsp;学生高效通过考试。
            </p>
            </div>
                `,txtPE1: `
                <p class="tit_en">
                First-class Faculties
            </p>
            <div class="txt_en">
            <p>
                Our teachers all come from the six major
            </p>
            <p>
            normal universities majoring in Chinese, al
            </p>
            <p>
            have International Chinese/Qualification 
            </p>
            <p>
            Certificates, more than 80% have a master's
            </p>
            <p>
            degree or above in 211.
            </p>
            <p>
                ZhiXing strengthen the difficult and 
            </p>
            <p>
            key points aiming at the HSK exam, to 
            </p>
            <p>
            help students pass the exam efficiently.
            </p>
            </div>
                `,
                txt2: `
                <p class="phgzn">
                Systematic and Personalized
            </p>
            <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Collect important and high-frequency
        </p>
        <p>
        &nbsp;&nbsp;points into thematic modules according
        </p>
        <p>
        &nbsp;&nbsp;to the HSK exam, so the students with
        </p>
        <p>
        &nbsp;&nbsp;different foundations could quickly fill in
        </p>
        <p>
        &nbsp;&nbsp;the missing and weakness.
        </p>
        <p>
        &nbsp;&nbsp;&nbsp; &nbsp; By flexible use of this modular system,
        </p>
        <p>
        &nbsp;&nbsp;our curriculum can be both personalized
        </p>
        <p>
        &nbsp;&nbsp;and systematic, helping students improve
        </p>
        <p>
        &nbsp;&nbsp;the potential score with efficiency.
        </p>
                `,
                txtPE2: `
                <p class="tit_en">Systematic and Personalized </p>
            <div class="txt_en">
                <p>
                    Collect important and high-frequency
                </p>
                <p>
                knowledge into thematic modules, so the
                </p>
                <p>
                students with different foundations could
                </p>
                <p>
                quickly fill in the missing and weakness.
                </p>
                <p>
                    By flexible use of this modular system,
                </p>
                <p>
                our curriculum can be both personalized
                </p>
                <p>
                and systematic, helping students improve
                </p>
                <p>
                the potential score with efficiency.
                </p>
            </div>
                `,
                txt21: `
                <p class="phgzn">
                Systematic and Personalized
            </p>
            <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Collect important and high-frequency
        </p>
        <p>
        &nbsp;&nbsp;knowledge into thematic modules, so the 
        </p>
        <p>
        &nbsp;&nbsp;students with different foundations could 
        </p>
        <p>
        &nbsp;&nbsp;quickly fill in the missing and weakness.
        </p>
        <p>
        &nbsp;&nbsp;&nbsp; &nbsp; By flexible use of this modular system,
        </p>
        <p>
        &nbsp;&nbsp;our curriculum can be both personalized
        </p>
        <p>
        &nbsp;&nbsp;and systematic, helping students improve
        </p>
        <p>
        &nbsp;&nbsp;the potential score with efficiency.
        </p>
                `,
                txt3: `
                <p class="phgzn">
                Inspiring Teaching
</p>
<p>
    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Inspire students&#39; divergent and active&nbsp;
</p>
<p>
    &nbsp;&nbsp;&nbsp;thinking by using an in-depth style to
</p>
<p>
    &nbsp;&nbsp;&nbsp;teach difficult knowledge in a lively and&nbsp;
</p>
<p>
    &nbsp;&nbsp;&nbsp;interesting way. Combine classic cultural
</p>
<p>
    &nbsp;&nbsp;&nbsp;stories and narrative scenes to realize&nbsp;
</p>
<p>
    &nbsp;&nbsp;&nbsp;the application and accurate meaning.
</p>
<p>
    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Stimulate the potential of strong&nbsp;
</p>
<p>
    &nbsp;&nbsp;&nbsp;areas to enhance learning interest, and&nbsp;
</p>
<p>
    &nbsp;&nbsp;&nbsp;complement the weak areas.
</p>
                `,
                txtPE3: `
                <p class="tit_en">Inspiring Teaching</p>
            <div class="txt_en">
                <p>
                Inspire students' divergent and active
                </p>
                <p>
                thinking by using an in-depth style to teach
                </p>
                <p>
                in a lively and interesting way. Combine 
                </p>
                <p>
                cultural stories and narrative scenes to 
                </p>
                <p>
                realize the application/ accurate meaning.
                </p>
                <p>
                Stimulate the potential of strong areas to 
                </p>
                <p>
                enhance learning interest, and complement
                </p>
                <p>
                the weak areas.
                </p>
            </div>
                `,
                txt4: `
                <p class="phgzn">
                Exam-oriented Training
</p>
<p>
&nbsp;&nbsp;&nbsp; &nbsp; Provide question banks to students,&nbsp;
</p>
<p>
&nbsp;&nbsp;&nbsp;which are filled with important and&nbsp;
</p>
<p>
&nbsp;&nbsp;&nbsp;high-frequency points. Offer individual&nbsp;
</p>
<p>
&nbsp;&nbsp;&nbsp;strategies and mock examination too.
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; The mock examination are design&nbsp;
</p>
<p>
&nbsp;&nbsp;&nbsp;based on the pasted examination&nbsp;
</p>
<p>
&nbsp;&nbsp;&nbsp;questions, predicts the most likely test&nbsp;
</p>
<p>
&nbsp;&nbsp;&nbsp;points. Students can efficiently use&nbsp;
</p>
<p>
&nbsp;&nbsp;&nbsp;that to deal with the exam.
</p>
                `,
                txtPE4: `
                <p class="tit_en">Exam-oriented Training</p>
            <div class="txt_en">
                <p>
                Provide question banks to students, which
                </p>
                <p>
                are filled with important and high-frequency
                </p>
                <p>
                points. Offer individual strategies and mock 
                </p>
                <p>
                examination too.
                </p>
                <p>The mock examination are design based
                </p>
                <p>
                on the pasted examination questions, 
                </p>
                <p>
                predicts the most likely test points. Students
                </p>
                <p>
                can efficiently deal with the exam.
                </p>
            </div>
                `,
                ctit1: "Comprehensively improve Chinese knowledge with classical corpus themes.",
                ctitPE1: `
                &nbsp; &nbsp;Comprehensively improve Chinese knowledge <br/>
                with classical corpus themes.<br/> 
                <div class="jianju"></div>
                &nbsp; &nbsp; Help students understand and comprehensively  <br/>
                tmaster the knowledge with marginal-increasing<br/>
                teaching method and classic corpus design.<br/>
                `,
                ctit2: "Help students deeply understand and comprehensively master the knowledge with marginal-increasing teaching method and classic corpus design.",
            },
            Lear: {
                txt1: `
                <p>
    1. Immersive language environment: We create an immersive language&nbsp;
</p>
<p>
    environment by imitating the process of language acquisition for infants.&nbsp;
</p>
<p>
    ZhiXing emphasize the development of students&#39; &quot;Language sensibility&quot;&nbsp;
</p>
<p>
    by strengthening the use of body language and story context to more&nbsp;
</p>
<p>
    accurately understand the meaning of words.
</p>
                `,
                txtPE1: `
    1. Immersive language environment: We create an immersive language 
    environment by imitating the process of language acquisition for infants. 
    ZhiXing emphasize the development of students&#39; &quot;Language sensibility&quot; 
    by strengthening the use of body language and story context to more 
    accurately understand the meaning of words.
    `,
                txt2: `
                <p>
    2. Marginal increasing teaching: According to Rasher&#39;s Monitor Theory,&nbsp;
</p>
<p>
    learners gradually strengthen their language learning effect through the&nbsp;
</p>
<p>
    understanding of language input, which is exactly the same as the&nbsp;
</p>
<p>
    characteristics of Chinese.
</p>
<p>
    Just like human cognitive thinking, the formation of Chinese characters&nbsp;
</p>
<p>
    evolves from specific to abstract, and becomes progressively more complex
</p>
<p>
    (pictograph, self explanatory, associative compounds, phonogram, mutually&nbsp;
</p>
<p>
    explanatory and phonetic characters). In response to these characteristics,&nbsp;
</p>
<p>
    ZhiXing&#39;s teaching style also places more emphasis on the development and&nbsp;
</p>
<p>
    evolutionary logic between words, so the knowledge could be increased in a&nbsp;
</p>
<p>
    Marginal way based on understanding, rather than rote learning.
</p>
                `,
                txtPE2: `
    2. Marginal increasing teaching: According to Rasher&#39;s Monitor Theory,
    learners gradually strengthen their language learning effect through the
    understanding of language input, which is exactly the same as the
    characteristics of Chinese.<br/>
    Just like human cognitive thinking, the formation of Chinese characters
    evolves from specific to abstract, and becomes progressively more complex
    (pictograph, self explanatory, associative compounds, phonogram, mutually
    explanatory and phonetic characters). In response to these characteristics,
    ZhiXing&#39;s teaching style also places more emphasis on the development and
    evolutionary logic between words, so the knowledge could be increased in a
    Marginal way based on understanding, rather than rote learning.
                `,
                txt3: `
                <p>
    3. Enhanced cultural contextualization: According to the linguistic environment
</p>
<p>
    theory, second language learning is actually a process of &quot;cultural cognition&quot;,&nbsp;
</p>
<p>
    and the degree of learner&#39;s transfer to the target language determines their&nbsp;
</p>
<p>
    learning process.
</p>
<p>
    In fact, the most difficult part of learning Chinese is the cultural understanding&nbsp;
</p>
<p>
    of vocabulary and intention. For example, it is difficult to accurately grasp the&nbsp;
</p>
<p>
    true meaning of words such as &quot;mianzi&quot;, &quot;jianghu&quot;, &quot;shanghuo&quot; and &quot;fengshui&quot;&nbsp;
</p>
<p>
    without the knowledge of eastern culture as the basis.
</p>
                `,
                txtPE3: `
    3. Enhanced cultural contextualization: According to the linguistic environment
    theory, second language learning is actually a process of &quot;cultural cognition&quot;,
    and the degree of learner&#39;s transfer to the target language determines their
    learning process.<br/>
    In fact, the most difficult part of learning Chinese is the cultural understanding
    of vocabulary and intention. For example, it is difficult to accurately grasp the
    true meaning of words such as &quot;mianzi&quot;, &quot;jianghu&quot;, &quot;shanghuo&quot; and &quot;fengshui&quot;
    without the knowledge of eastern culture as the basis.
                `,
                txt4: `
                <p>
    4.Mapping associative memory:The golden age of language learning is 2-4&nbsp;
</p>
<p>
    years old, when the &quot;Broca&#39;s area&quot; of the brain develops rapidly. By missing&nbsp;
</p>
<p>
    this period, the brain is less able to store the language directly in Broca&#39;s area,
</p>
<p>
    and instead it will stores in memory. At this point, it is particularly important to&nbsp;
</p>
<p>
    strengthen the connection between these two regions through the&nbsp;
</p>
<p>
    transformation of lexical meaning.
</p>
<p>
    ZhiXing attaches great importance to the Chinese environment of overseas&nbsp;
</p>
<p>
    students, and tends to use their customary language to explain Chinese&nbsp;
</p>
<p>
    vocabulary, so as to strengthen the linkage between the two brain regions&nbsp;
</p>
<p>
    and consolidate the learning effect of the second language.
</p>
                `,
                txtPE4: `
    4.Mapping associative memory:The golden age of language learning is 2-4
    years old, when the &quot;Broca&#39;s area&quot; of the brain develops rapidly. By missing
    this period, the brain is less able to store the language directly in Broca&#39;s area,
    and instead it will stores in memory. At this point, it is particularly important to
    strengthen the connection between these two regions through the
    transformation of lexical meaning.<br/>
    ZhiXing attaches great importance to the Chinese environment of overseas
    students, and tends to use their customary language to explain Chinese
    vocabulary, so as to strengthen the linkage between the two brain regions
    and consolidate the learning effect of the second language.
                `,
                tit: `"Four-Dimensional Learning Theory"`,
                titPE: `"Four-Dimensional<br/>Learning Theory"`,
                tit1: `ZhiXing has come up with a "Four-Dimensional Learning Theory" 
                which is more suitable for Chinese teaching. It is based on the 
                "Second Language Acquisition" theory and combining the 
                characteristics of Chinese language.`,
                but1: `
                Immersive Language 
                <p>Environment</p>
                `,
                but2: "<p>Marginal</p> Increasing Teaching",
                but3: "<p>Enhanced Cultural</p> Contextualization",
                but4: "<p>Mapping</p> Associative Memory",
            },
            Course: {
                ctit1: "Designed for overseas Chinese students, comprehensively strengthens basic Chinese ability through systematic teaching.",
                ctitPE1:`
                &nbsp; &nbsp; &nbsp; &nbsp;Designed for overseas Chinese students, <br/>
                comprehensively strengthens basic Chinese ability.<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;Teaching Chinese knowledge comprehensively <br/>
                and systematically, with enlightening and interesting,<br/>
                covering all kinds of  language scenes in life and<br/>
                study, and laying a solid foundation for the future <br/>
                (IB, AP,  A-Level, O-lelel, GCSE, etc.).
                `,
                ctit2: "Teaching basic Chinese knowledge comprehensively and systematically with enlightening and interesting, covering all kinds of ",
                ctit4: "Thematic teaching focuses on test points and expands relevant knowledge.",
                ctitPE4: `
                &nbsp; &nbsp;Thematic teaching focuses on test points and<br/>
                expands relevant knowledge.<br/> 
                <div class="jianju"></div>
                &nbsp; &nbsp;Use classic corpus design and marginal increasing <br/>
                teaching to help students mater knowledge and <br/>
                comprehensively improve.<br/>
                `,
                ctitPE4two: `
                &nbsp; &nbsp;ZhiXing helps to make dreams come ture.<br/>
                <div class="jianju"></div>
                &nbsp; &nbsp;Get more opportunities to choose schools; Obtain <br/> 
                credits in advance and strive for scholarships; <br/> 
                Increase GPA, and realize the dream of famous <br/> 
                universities.
                `,
                ctitPE4three: `
                &nbsp; &nbsp;Share China's development opportunities<br/>
                through HSK exam.<br/>
                <div class="jianju"></div>
                &nbsp; &nbsp;The HSK exam could help you work(entry/<br/> 
                promotion), study(admission/credit/scholarship), or<br/> 
                stay (visa/talent recognition) in China. 
                `,
                ctit5: "Use classic corpus design and marginal increasing teaching to help students mater knowledge and comprehensively improve.",
                ctit3: "language scenes in life and study, and laying a solid foundation for the future (HSK, O-level, A-level, AP, IB, etc.).",
                tit1: "Use thematic teaching to strengthen the Chinese foundation, corresponding to domestic language textbooks.",
                titPE1:`
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;Use thematic teaching to strengthen the <br/>
                Chinese foundation, corresponding to domestic<br/> 
                language textbooks.<br/> 
                <div class="jianju"></div>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;Use vivid teaching themes to guide students' <br/>
                mind, inspire initiative and divergent thinking, help <br/>
                them understand deeply and achieve mastery.<br/>
                `,
                tit2: "Use vivid teaching themes to guide students' mind, inspire initiative and divergent thinking, help them understand deeply and achieve mastery.",
            },
            yybutton: "Book a Free Trial Now",
            Footer: {
                follow: "Follow ZhiXing",
                ztit1: "Chinese Course",
                ztit2: "Chinese Exam",
                ztit3: "Q&A",
                ztit4: "Book a Free Trial",
                ytit1: "ZhiXing Faculty",
                ytit2: "ZhiXing Advantage",
                ytit3: "Exam Information",
                ytit4: "Extended Reading",
            },
            QA: {
                txt1: `    Online teaching, 1V1, 55 minutes per section; We offer 5 different courses currently, include: Juvenile 
                Course, HSK standard Course, AP Chinese Course, IB Chinese Course and O-LEVEL Chinese Course. `,
                txt2: `    We have the first-rate faculties in China,our teachers all come from the six major normal universities 
                directly under the Ministry of Education, majoring in Chinese language, more than 80% teachers have a 
                master's degree or above in 211. We employ College professors to customize our curriculum. 
                    We also cooperate with Talk-Cloud to provide a stable Internet connection, allowing students to have a 
                high-quality Chinese course at home.`,
                txt3: `    At the end of each stage of learning, ZhiXing will regularly hold a "parent-teacher meeting" to introduce 
                the situation over a period of time and help parents grasp the learning situation of students.
                    ZhiXing has a course record function, which allows parents to review the course on the learning software,
                so they can monitor the status of students and the quality of teaching.`,
                txt4: `    The teacher usually assigns homework after each class, to help students practice and consolidate the 
                content; After that, the teacher will corrects the homework and uploaded it to the learning software, 
                providing feedback and explanations for the incorrect answers.
                    For students with examination needs, we will also provide extended exercises and a special examination 
                question bank for them.`,
                txt5: `    After registering for a ZhiXing account, you can scan the code to add customer service (We Chat). 
                Customer service will match the student's needs with the right teacher and arrange a trial lesson.
                    You can also click "Free Trial" and leave your information, our customer service will contact you later.`,
                txt6: `    A brief introduction to the curriculum, followed by a introduction of the teaching content.
                In the trial process, the teacher will introduce the teaching plan according to the students' basal level, 
            and also put forward specific suggestions for the students' future Chinese learning.`,
                txt7: `    Sure.
                If the teacher was found incompetent to the class, or failure to communicate with the student, you can 
            always put forward this request to change the teacher.
                Given the integrity and consistency of the curriculum, it is ideal for students to stay with one teacher for 
            a long period of time. Therefore, we hope that you will have the patience and necessary trust in teachers.`,
                txt8: `    Each course is about $20 (55 minutes), depending on the type of course. Detailed pricing information 
                can be viewed in the member center after registration, or you can ask our customer service.`,
                txt9: `    We accept Visa and Master Card, Payoneer, WeChat, Alipay, and inter-bank remittance ....`,
                txt10: `    We accept payment in US dollar, RMB, Singapore Dollar, Euro and other currencies.`,
                txt11: `    Please install the learning software on the smart device according to the ZhiXing Teaching System User 
                Guide (for best network quality, it is recommended not to use the web page).
                    After that, please test the working conditions of cameras, earphones and other devices according to the 
                software instructions; Enter the online classroom 5-10 minutes in advance and get prepared:`,
                txt12: `    Windows/Mac computers, as well as apple/Android smart devices.`,
                txt13: `    A physical network cable is recommended, or a wireless connection rate of 10M/S or higher. Try to avoid 
                high traffic network load during the class.`,
                txt14: `    You can contact customer service to get the password, or reset it on the member page.`,

                
                txtPE1: `  <span>  Online teaching, 1V1, 55 minutes per section; We offer 5 different courses currently, include: Juvenile 
                Course, HSK standard Course, AP Chinese Course, IB Chinese Course and O-LEVEL Chinese Course. </span> `,
                txtPE2: `  <span>  We have the first-rate faculties in China,our teachers all come from the six major normal universities 
                directly under the Ministry of Education, majoring in Chinese language, more than 80% teachers have a 
                master's degree or above in 211. We employ College professors to customize our curriculum. 
                    We also cooperate with Talk-Cloud to provide a stable Internet connection, allowing students to have a 
                high-quality Chinese course at home.</span>`,
                txtPE3: `  <span>  At the end of each stage of learning, ZhiXing will regularly hold a "parent-teacher meeting" to introduce 
                the situation over a period of time and help parents grasp the learning situation of students.
                    ZhiXing has a course record function, which allows parents to review the course on the learning software,
                so they can monitor the status of students and the quality of teaching.</span>`,
                txtPE4: `  <span>  The teacher usually assigns homework after each class, to help students practice and consolidate the 
                content; After that, the teacher will corrects the homework and uploaded it to the learning software, 
                providing feedback and explanations for the incorrect answers.
                    For students with examination needs, we will also provide extended exercises and a special examination 
                question bank for them.</span>`,
                txtPE5: `  <span>  After registering for a ZhiXing account, you can scan the code to add customer service (We Chat). 
                Customer service will match the student's needs with the right teacher and arrange a trial lesson.
                    You can also click "Free Trial" and leave your information, our customer service will contact you later.</span>`,
                txtPE6: `  <span>  A brief introduction to the curriculum, followed by a introduction of the teaching content.
                In the trial process, the teacher will introduce the teaching plan according to the students' basal level, 
            and also put forward specific suggestions for the students' future Chinese learning.</span>`,
                txtPE7: `  <span>  Sure.
                If the teacher was found incompetent to the class, or failure to communicate with the student, you can 
            always put forward this request to change the teacher.
                Given the integrity and consistency of the curriculum, it is ideal for students to stay with one teacher for 
            a long period of time. Therefore, we hope that you will have the patience and necessary trust in teachers.</span>`,
                txtPE8: `  <span>  Each course is about $20 (55 minutes), depending on the type of course. Detailed pricing information 
                can be viewed in the member center after registration, or you can ask our customer service.</span>`,
                txtPE9: `   <span> We accept Visa and Master Card, Payoneer, WeChat, Alipay, and inter-bank remittance ....</span>`,
                txtPE10: `<span>    We accept payment in US dollar, RMB, Singapore Dollar, Euro and other currencies.</span>`,
                txtPE11: `<span> Please install the learning software on the smart device according to the ZhiXing Teaching System User 
                Guide (for best network quality, it is recommended not to use the web page).
                    After that, please test the working conditions of cameras, earphones and other devices according to the 
                software instructions; Enter the online classroom 5-10 minutes in advance and get prepared:</span>`,
                txtPE12: `<span> Windows/Mac computers, as well as apple/Android smart devices.</span>`,
                txtPE13: `<span> A physical network cable is recommended, or a wireless connection rate of 10M/S or higher. Try to avoid 
                high traffic network load during the class.</span>`,
                txtPE14: `<span> You can contact customer service to get the password, or reset it on the member page.</span>`,

                toptit1: "Course Introduction",
                toptit2: "Purchase Problem",
                toptit3: "Usage Details",
                toptitPE1: `<span> Course Introduction </span>`,
                toptitPE2: `<span> Purchase Problem </span>`,
                toptitPE3: `<span> Usage Details </span>`,
                tit1: "What is the course format of ZhiXing?",
                tit2: "What are the advantages of ZhiXing compared with other companies?",
                tit3: "How can parents grasp their children's learning situation?",
                tit4: "Is there a daily homework? Will the teacher correct it?",
                tit5: "How to book a free trial lesson?",
                tit6: "What is the content of the trial lesson?",
                tit7: "Is it possible to change teachers in the learning process?",
                tit8: "What are the prices of Chinese courses?",
                tit9: "What are the available payment options?",
                tit10: "Which currencies are accepted?",
                tit11: "Any preparation for the first lesson?",
                tit12: "What kind of smart devices can the learning software support?",
                tit13: "What are the specific requirements for the network?",
                tit14: "What should I do if I forget my login password?",
                titPE1: `<span>What is the course format of ZhiXing?</span>`,
                titPE2: `<span>What are the advantages of ZhiXing compared with other...</span>`,
                titPE3: `<span>How can parents grasp their children's learning situation?</span>`,
                titPE4: `<span>Is there a daily homework? Will the teacher correct it?</span>`,
                titPE5: `<span>How to book a free trial lesson?"</span>`,
                titPE6: `<span>What is the content of the trial lesson?"</span>`,
                titPE7: `<span>Is it possible to change teachers in the learning process?</span>`,
                titPE8: `<span>What are the prices of Chinese courses?</span>`,
                titPE9: `<span>What are the available payment options?</span>`,
                titPE10: `<span>Which currencies are accepted?</span>`,
                titPE11: `<span>Any preparation for the first lesson?</span>`,
                titPE12: `<span>What kind of smart devices can the learning software...</span>`,
                titPE13: `<span>What are the specific requirements for the network?</span>`,
                titPE14: `<span>What should I do if I forget my login password?</span>`,
            },
            Concept: `
            <p>
    &nbsp; &nbsp; &quot;ZhiXing&quot; originated from the philosophy of the thinker Wang YangMing in the Ming Dynasty:&nbsp;
</p>
<p>
    &quot;the unity of knowledge and action&quot;, which emphasize the inseparable relationship between&nbsp;
</p>
<p>
    knowledge and action in the process of cognition, he believed that knowledge and action are&nbsp;
</p>
<p style="margin-bottom: 0.05rem;">
    intertwined, dialectical and alternately enhanced.
</p>
<p>
    &nbsp; &nbsp; Just like our teaching philosophy, we want to show the Chinese cultural phenomena and&nbsp;
</p>
<p>
    concepts to students, instead of the memory-based teaching limited to vocabulary and&nbsp;
</p>
<p>
    phrases, or the complicated language tool itself, which is very important for students who&nbsp;
</p>
<p style="margin-bottom: 0.05rem;">
    want to study and develop in the Chinese world in the future.
</p>
<p>
    &nbsp; &nbsp; Language is the carrier of culture, which needs to be learned step by step. We can only master
</p>
<p>
    this language through sustaining training and correction in &quot;knowledge&quot; and &quot;action&quot;. And ZhiXing&nbsp;
</p>
<p style="margin-bottom: 0.05rem;">
    will be the place where all these begin.
</p>
            `,
            Concept1: `
            "ZhiXing" originated from the philosophy of the thinker 
            Wang YangMing in the Ming Dynasty: "the unity of 
            knowledge and action", which emphasize the inseparable 
            relationship between knowledge and action in the process 
            of cognition, he believed that knowledge and action are 
            intertwined, dialectical and alternately enhanced. 
            `,
            Concept2: `
            Just like our teaching philosophy, we want to show the Chinese cultural phenomena and concepts to students, instead of the memory-based teaching limited to vocabulary and phrases, or the complicated language tool itself, which is very important for students who want to study and develop in the Chinese world in the future.
            `,
            Concept3: `
            Language is the carrier of culture, which needs to be learned step by step. We can only master this language through sustaining training and correction in "knowledge" and "action". And ZhiXing will be the place where all these begin.
            `,
            Concept1Button: "Load more",
            Concept2Button: "Retract",
            Teach: {
                tlang: "Teaching languages:",
                CHIN: "CHIN",
                ENG: "ENG",
                KO: "KO",
                CANT: "CANT",
                year: "Yr",
                hours: "teaching hours",
            },
            Character: {
            titlePE1: `First-rate professional teachers <br/> Provide the best teaching resources`,
            titlePE12: `
            <div class="txt_box">
            &nbsp;&nbsp;&nbsp;&nbsp;Our teachers all come from the six major normal <br/>
            universities directly under the Ministry of Education,<br/>
            majoring in Chinese language and literature, with <br/>
            more than 3 years of front-line teaching experience, <br/>
            more than 80% teachers have a master's degree or <br/>
            above in 211. <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;We cooperate with the College of Arts of<br/>
            Southwest University and HuaZhong Normal <br/>
            University, employ professors to customize the<br/>
            curriculum.<br/>
            </div>
            `,
            titlePE2: `Teaching with fun and inspiration <br/> Inspire the learning and application`,
            titlePE22: `
            <div class="txt_box txt_box_2">
            &nbsp;&nbsp;&nbsp;&nbsp;Inspire students' divergent and active thinking<br/>
            by using an in-depth teaching style to teach<br/>
            difficult knowledge in a lively and interesting way<br/>
            Combine classic cultural stories and narrative <br/>
            scenes to realize the application and accurate <br/>
            meaning of knowledge. <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;Guide students to immerses in thematic cultural<br/>
            stories and narrative scenes, help them<br/>
            understand cultural phenomena and concepts <br/>
            beyond language tools.<br/>
            </div>
            `,
            titlePE3: `Systematic graded teaching <br/> Customized teaching for students`,
            titlePE32: `
            <div class="txt_box txt_box_3">
            &nbsp;&nbsp;&nbsp;&nbsp;Collect the common, high frequency and<br/>
            important knowledge to form a modular<br/>
            knowledge base, which is convenient for<br/>
            students with different foundations and goalsto<br/>
            quickly fill in the weakness and missing.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;Make the courses both personalized and <br/>
            systematic by flexible using of this knowledge<br/>
            module base. Improve students' potential <br/>
            scores while ensuring the efficiency of learning. <br/>
            </div>
            `,
            titlePE4: `Teaching in students' way <br/> Guide with open-ended questions`,
            titlePE42: `
            <div class="txt_box txt_box_4">
            &nbsp;&nbsp;&nbsp;&nbsp;Teaching system are designed in students'<br/>
            way, guide them to understand and master<br/>
            the knowledge with their own mind.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;With no rigid teaching, with no authority and<br/>
            absolute accuracy, we encourage students to<br/>
            learn and study in their own ways. We believe <br/>
            the knowledge increase step by step, only<br/>
            when the student accept it ,they can truly <br/>
            learn it.<br/>
            </div>
            `,
            titlePE5: `Focus on overseas students <br/> Targeted and personalized teaching`,
            titlePE52: `
            <div class="txt_box txt_box_5">
            &nbsp;&nbsp;&nbsp;&nbsp;Pay attention to students' foundation and<br/>
            characteristics, teaching common content <br/>
            from personality, guide them from a point to<br/>
            the surface; Teaching normal Chinese based<br/>
            on daily life and study.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;Stimulate the potential of strong areas to<br/>
            enhance learning interest, and complement<br/>
            the weak areas.<br/>
            </div>
            `,
            titlePE6: `Combines study and examination <br/> Full improvement of L/S/R/W skills`,
            titlePE62: `
            <div class="txt_box txt_box_6">
            &nbsp;&nbsp;&nbsp;&nbsp;The teaching system combines both basic <br/>
            Chinese teaching and entrance examination, <br/>
            forming a scientific knowledge framework and<br/>
            Chinese advanced map. So the curriculum can be<br/>
            developed on this system to form a knowledge <br/>
            module library.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;Comprehensively improve listening, speaking,<br/>
            reading and writing abilities , taking examination<br/>
            syllabus as the goal, using the knowledge module <br/>
            system as the auxiliary.
            </div>
            `,
                title6: "Combines study and examination",
                title61: "Full improvement of L/S/R/W skills",
                text6: `
                <p style="margin-top:0.07rem;">
    &nbsp; &nbsp; The teaching system combines both basic&nbsp;
</p>
<p>
    Chinese teaching and entrance examination,&nbsp;
</p>
<p>
    forming a scientific knowledge framework and
</p>
<p>
    Chinese advanced map. So the curriculum&nbsp;
</p>
<p>
    can be developed on this system to form a&nbsp;
</p>
<p>
    knowledge module library.
</p>
<p>
    &nbsp; &nbsp; Comprehensively improve listening,&nbsp;
</p>
<p>
    speaking, reading and writing abilities , taking&nbsp;
</p>
<p>
    examination syllabus as the goal, using the&nbsp;
</p>
<p>
    knowledge module system as the auxiliary.
</p>
                `,

                title5: "Focus on overseas students",
                title51: "Targeted and personalized teaching",
                text5: `
                <p style="margin-top:0.2rem;">
    &nbsp; &nbsp; Pay attention to students&#39; foundation and&nbsp;
</p>
<p>
    characteristics, teaching common content&nbsp;
</p>
<p>
    from personality, guide them from a point to
</p>
<p>
    the surface; Teaching normal Chinese based
</p>
<p>
    on daily life and study.
</p>
<p>
    &nbsp; &nbsp; Stimulate the potential of strong areas to&nbsp;
</p>
<p>
    enhance learning interest, and complement&nbsp;
</p>
<p>
    the weak areas.
</p>
                `,

                title4: "Teaching in students' way",
                title41: "Guide with open-ended questions",
                text4: `
                <p style="margin-top:0.2rem;">
    &nbsp; &nbsp; Teaching system are designed in students&#39;
</p>
<p>
    way, guide them to understand and master&nbsp;
</p>
<p>
    the knowledge with their own mind.
</p>
<p>
    &nbsp; &nbsp; With no rigid teaching, with no authority and&nbsp;
</p>
<p>
    absolute accuracy, we encourage students to&nbsp;
</p>
<p>
    learn and study in their own ways. We believe&nbsp;
</p>
<p>
    the knowledge increase step by step, only&nbsp;
</p>
<p>
    when the student accept it ,they can truly&nbsp;
</p>
<p>
    learn it.
</p>
                `,

                title3: "Systematic graded teaching",
                title31: "Customized teaching for students",
                text3: `
                <p style="margin-top:0.1rem;">
    &nbsp; &nbsp; Collect the common, high frequency and&nbsp;
</p>
<p>
    important knowledge to form a modular&nbsp;
</p>
<p>
    knowledge base, which is convenient for&nbsp;
</p>
<p>
    students with different foundations and goals
</p>
<p>
    to quickly fill in the weakness and missing.
</p>
<p>
    &nbsp; &nbsp; Make the courses both personalized and&nbsp;
</p>
<p>
    systematic by flexible using of this knowledge
</p>
<p>
    module base. Improve students&#39; potential&nbsp;
</p>
<p>
    scores while ensuring the efficiency of&nbsp;
</p>
<p>
    learning.
</p>
                `,

                title2: "Teaching with fun and inspiration",
                title21: "Inspire the learning and application",
                text2: `
                <p style="margin-top:0.1rem;">
    &nbsp;&nbsp;&nbsp; &nbsp; Inspire students&#39; divergent and active&nbsp;
</p>
<p>
    &nbsp;&nbsp;thinking by using an in-depth teaching style&nbsp;
</p>
<p>
    &nbsp;&nbsp;to teach difficult knowledge in a lively and&nbsp;
</p>
<p>
    &nbsp;&nbsp;interesting way. Combine classic cultural&nbsp;
</p>
<p>
    &nbsp;&nbsp;stories and narrative scenes to realize the&nbsp;
</p>
<p>
    &nbsp;&nbsp;application and accurate meaning of&nbsp;
</p>
<p>
    &nbsp;&nbsp;knowledge.
</p>
<p>
    &nbsp;&nbsp;&nbsp; &nbsp; Guide students to immerses in thematic&nbsp;
</p>
<p>
    &nbsp;&nbsp;cultural stories and narrative scenes, help&nbsp;
</p>
<p>
    &nbsp;&nbsp;them understand cultural phenomena and&nbsp;
</p>
<p>
    &nbsp;&nbsp;concepts beyond language tools.
</p>
                `,
                title1: "First-rate professional teachers",
                title11: "Provide the best teaching resources",
                text1: `
                <p style="margin-top:0.1rem;">
    &nbsp; &nbsp; Our teachers all come from the six major&nbsp;
</p>
<p>
    normal universities directly under the Ministry&nbsp;
</p>
<p>
    of Education, majoring in Chinese language&nbsp;
</p>
<p>
    and literature, with more than 3 years of&nbsp;
</p>
<p>
    front-line teaching experience, more than 80%
</p>
<p>
    teachers have a master&#39;s degree or above in&nbsp;
</p>
<p>
    211.&nbsp;
</p>
<p>
    &nbsp; &nbsp; We cooperate with the College of Arts of&nbsp;
</p>
<p>
    Southwest University and HuaZhong Normal&nbsp;
</p>
<p>
    University, employ professors to customize&nbsp;
</p>
<p>
    the curriculum.
</p>
                `,
            },
            CourseType: {
                ol11: `
                <div class="html_ol11">
                <div>
    Be familiar with the question type; Master
    </div>
<div>
    the high-frequency question and topics;
    </div>
<div>
    Strengthen L/S/R/W skills around the
    </div>
<div>
    examination syllabus; Strengthen the
    </div>
<div>
    accumulation of words(idioms); Master
    </div>
<div>
    common corpus expression; Skillfully
    </div>
<div>
    switching CHIN-ENG intentions; Master
    </div>
<div>
    the strategies of answering questions
    </div>
<div>
    and scoring skills.
    </div>
</div>
                `,
                olPE11: `
                <div class="html_en_ib11">
                <div>
                Familiar with the question type; Master
    </div>
<div>
high-frequency question and topics; 
    </div>
<div>
Strengthen L/S/R/W skills around the 
    </div>
<div>
examination syllabus; Strengthen the
    </div>
<div>
accumulation of words(idioms); Master
    </div>
<div>
common corpus expression; Skillfully 
    </div>
<div>
switching CHIN-ENG intentions; 
    </div>
<div>
Master the strategies of scoring skills.
    </div>
</div>
                `,
                ol12: `
                <div class="html_ol12">
                <div>
    L: Identify key messages, narrative logic&nbsp;

    </div>
<div>
    &nbsp; &nbsp; and emotions in common expressions;

    </div>
<div>
    S: Read fluently, express the opinions&nbsp;

    </div>
<div>
    &nbsp; &nbsp; clearly with logic and emotion;

    </div>
<div>
    R: Analyze the structure, theme and emotion

    </div>
<div>
    &nbsp; &nbsp; of the article, improve reading speed,

    </div>
<div>
    &nbsp; &nbsp; complete the conversion of CHIN-ENG;

    </div>
<div>
    W: Master writing template of typical style,&nbsp;

    </div>
<div>
    &nbsp; &nbsp; accumulate current political themes;
    </div>
    </div>
                `,
                olPE12: `
                <div class="html_en_ib12">
                <div>
                L: Identify key message, narrative logic 

    </div>
<div>
    &nbsp; &nbsp; and emotions in common topics;

    </div>
<div>
S: Read fluently, express the opinions

    </div>
<div>
    &nbsp; &nbsp; clearly with logic and emotion;

    </div>
<div>
R: Analyze the structure and theme of

    </div>
<div>
    &nbsp; &nbsp; article, improve reading speed, 

    </div>
<div>
    &nbsp; &nbsp; master the conversion of CHIN-ENG;

    </div>
<div>
W: Master writing template, accumulate

    </div>
<div>
    &nbsp; &nbsp; current political themes;
    </div>
    </div>
                `,
                ol13: `
                <div class="html_ol13" >

                <div>
    Political news;
    </div>
    <div>
   Narration training;
    </div>
    <div>
Argumentation training;
</div>
<div>
Email training;
    </div>
    <div>
Material composition training;
    </div>
    <div>
Idiom accumulation;
    </div>
    <div>
Semantic analysis;
</div>
<div>
Oral training;

    </div>

<div class="you_tit">
<p>Targeted</p>
Selection:
</div>

        </div>
                `,
                olPE13: `
                <div class="html_en_ib13" >

                <div>
    Political news;
    </div>
    <div>
   Narration training;
    </div>
    <div>
Argumentation training;
</div>
<div>
Email training;
    </div>
    <div>
Material composition training;
    </div>
    <div>
Idiom accumulation;
    </div>
    <div>
Semantic analysis;
</div>
<div>
Oral training;

    </div>

<div class="you_tit">
Targeted<br/>
Selection
<span>. .</span>
</div>

        </div>
                `,
                oltitle: `
<p style="text-align: center;margin-top:0.5rem;">
    O-level Course&nbsp;
</p>
<p style="text-align: center;">
    (Synthesized)
</p>
                `,
                olPEtitle: `O-level<br/>
                Chinese
                `,

                idtitle: `
                <p style="text-align: center;margin-top:0.5rem;">
    IB Course&nbsp;
</p>
<p style="text-align: center;">
    (Language B)
</p>
                `,
                idPEtitle: `
                IB<br/>Chinese<br/>B
                `,
                ib11: `
                <div class="html_ib11">
                <div >
    Be familiar with the question type; Master&nbsp;
    </div>
<div>
    the high-frequency question and topics;&nbsp;
    </div>
<div>
    Strengthen L/S/R/W skills around the five&nbsp;
    </div>
<div>
    subjects (identity, experience, invention,&nbsp;
        </div>
    <div>
    social organization, sharing earth); Master
    </div>
<div>
    cultural phenomena and cultural concepts;
    </div>
<div>
    Skillfully switching CHIN-ENG intentions;&nbsp;
    </div>
<div>
    Master the strategies of answering&nbsp;
    </div>
<div>
    questions and scoring skills.
    </div>
</div>
                `,
                ibPE11: `
                <div class="html_en_ib11">
                <div >
    familiar with the question type; Master
    </div>
<div>
    high-frequency question and topics;
    </div>
<div>
    Raise L/S/R/W skills around the five
    </div>
<div>
    subjects (identity, experience...sharing
        </div>
    <div>
    earth); Master cultural phenomena and
    </div>
<div>
     cultural concepts;Skillfully switching
    </div>
<div>
     CHIN-ENG intentions;Master the
    </div>
<div>
strategies of scoring skills.
    </div>
</div>
                `,
                ib12: `
                <div class="html_ib12">
            <div>
    L: Identify key messages, narrative logic
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;and emotions in common expressions;
    </div>
<div>
    S: Fluent in opinion expression, topic
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;deduction, and logical interpretation;
    </div>
<div>
    R: Analyze the structure of chapters,
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;master cultural customs and concepts,
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;complete the conversion of CHIN-ENG;
    </div>
<div>
    W: Master writing skills of different styles and
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;subjects, and classical writing framework;
</div>
</div>
                `,
                ibPE12: `
                <div class="html_en_ib12">
            <div>
    L: Identify key messages, narrative logic
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;and emotions in common topics;
    </div>
<div>
    S: Fluent in opinion expression, topic
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;deduction, and logical interpretation;
    </div>
<div>
    R: Analyse structure of topics,cultural
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;customs and concepts,master the
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;conversion of CHIN-ENG;
    </div>
<div>
    W: Master writing skills of diff styles 
    </div>
<div>
&nbsp;&nbsp;&nbsp;&nbsp;and subjects,classical framework;
</div>
</div>
                `,
                ib13: `
                <div class="html_ib13">
                <div>
                Identity (lifestyles, health and well-being, beliefs
                    </div>
                    <div>
                and values, subcultures, language and identity)
                </div>
                <div>
                Experience (leisure, holidays and travel, life
                    </div>
                    <div>
                stories, life rituals, customs and traditions...;)
                </div>
                <div>
                Invention (entertainment, artistic expression,
                    </div>
                    <div>
                technology, scientific innovation...;)
                </div>
                <div>
                Social organization (community, social&nbsp;
                    </div>
                    <div>
                participation, workplace, law and order...;)
                </div>
                <div>
                Sharing earth (environment, peace and conflict,
            </div>
            <div>
                ethics, environment, human rights, equality...;)
                </div>
            </div>
                `,
                ibPE13: `
                <div class="html_en_ib13">
                <div>
                Identity (lifestyles, health, beliefs and
                    </div>
                    <div>
                &nbsp; &nbsp; &nbsp;values, language and identity...)
                </div>
                <div>
                Experience (leisure, holidays and travel,
                    </div>
                    <div>
                &nbsp; &nbsp; &nbsp;life stories/rituals, traditions...)
                </div>
                <div>
                Invention (artistic, science, technology,
                    </div>
                    <div>
                    &nbsp; &nbsp; &nbsp;entertainment...)
                </div>
                <div>
                Social organization (community, social
                    </div>
                    <div>
                    &nbsp; &nbsp; &nbsp;participation, law and order...)
                </div>
                <div>
                Sharing earth (environment, peace and
            </div>
            <div>
            &nbsp; &nbsp; &nbsp;conflict, human rights, equality...)
                </div>
            </div>
                `,

                ap11: `
                <div class="html_ap11" >
                <div>
                Be familiar with the question type; Master
                </div>
           <div>
            the high-frequency question and topics;
            </div>
       <div>
            Strengthen L/S/R/W skills around the
            </div>
       <div>
            Syllabus 5C(Communities,Communication,
                </div>
           <div>
            Comparisons, Cultures, Connection);
            </div>
       <div>
            Understand cultural phenomena and
            </div>
       <div>
            concepts; Skillfully switching CHIN-ENG
            </div>
       <div>
            intentions; Master the strategies of
            </div>
       <div>
            answering questions and scoring skills.
                </div>
                </div>
                `,
                aptitle: "AP Course",
                apPEtitle: `AP<br/>Course`,
                apPE11: `
                <div class="html_en_ib11" >
                <div>
                Familiar with the question type; Master 
                </div>
           <div>
           high-frequency topics; Raise L/S/R/W
            </div>
       <div>
       skills around the Syllabus 5C(Cultures,
            </div>
       <div>
       Communities...Connection); Master the
                </div>
           <div>
           cultural phenomena and concepts; 
            </div>
       <div>
       Skillfully translate CHIN-ENG intentions;
            </div>
       <div>
       Master the strategies of scoring skills.
            </div>
                </div>
                `,
                ap12: `
           <div class="html_ap12">
                <div>
                L: Identify key messages, narrative logic&nbsp;
                </div>
           <div>
           &nbsp;&nbsp;&nbsp;&nbsp;and emotions in common expressions;
                </div>
           <div>
                 S: Fluent in opinion expression, topic&nbsp;
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;deduction, and logical interpretation;
                 </div>
            <div>
                 R: Analyze the structure of chapters,&nbsp;
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;master cultural customs and concepts,
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;complete the conversion of CHIN-ENG;
                 </div>
            <div>
                 W: Master writing skills of different styles and
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;subjects, and classical writing framework;
            </div>
            </div>
                `,
                apPE12: `
           <div class="html_en_ib12">
                <div>
                L: Identify key messages, narrative
                </div>
           <div>
           &nbsp;&nbsp;&nbsp;&nbsp;logic and emotions in common topics;
                </div>
           <div>
           S: Fluent in opinion expression, topic 
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;deduction, and logical interpretation;
                 </div>
            <div>
            R: Analyze structure of topics, cultural
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;customs and concepts, complete the
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;conversion of CHIN-ENG;
                 </div>
            <div>
            W: Master writing skills of diff styles 
                 </div>
            <div>
            &nbsp;&nbsp;&nbsp;&nbsp;and subjects, classical framework;
            </div>
            </div>
                `,
                ap13: ` 
                <div class="html_ap13">
                <div class="html_ap13_z">
                Chinese culture and concepts;
            </div>
            <div class="html_ap13_z">
                Traditional festivals and customs;
                </div>
                <div class="html_ap13_z">
                Tourist attractions and ancient buildings;
                </div>
                <div class="html_ap13_z">
                Literary works and four great novels;
                </div>
                <div class="html_ap13_z">
               Chinese diet and eight major cuisines;
               </div>
               <div class="html_ap13_z">
                Chinese painting , calligraphy and tools;
                </div>
                <div class="html_ap13_z">
                The four inventions and modern technology;
                </div>
                <div class="html_ap13_z">
                Chinese medicine and philosophy;
                </div>
            </div>
                `,
                apPE13: ` 
                <div class="html_en_ib13">
                <div class="html_ap13_z">
                Chinese culture and concepts;
            </div>
            <div class="html_ap13_z">
                Traditional festivals and customs;
                </div>
                <div class="html_ap13_z">
                Tourist attractions and anci buildings;
                </div>
                <div class="html_ap13_z">
                Literary works and four great novels;
                </div>
                <div class="html_ap13_z">
               Chinese diet and eight major cuisines;
               </div>
               <div class="html_ap13_z">
                Chinese painting , calligraphy and tools;
                </div>
                <div class="html_ap13_z">
                The four inventions and modern tech;
                </div>
                <div class="html_ap13_z">
                Chinese medicine and philosophy;
                </div>
            </div>
                `,
                hsk61: `
                <div class="html_hsk61">
                <div>Understand, summarize and analyze </div>
                <div>complex materials on various occasions </div>
                <div>and topics, participate in discussions.</div>
                <div>master some idioms and colloquial sayings.</div>
                </div>
                `,
                hsk62: `
                <div class="html_hsk12 html_hsk62">
                <div>L: Follow complex presentation in various fields;</div>
                <div >S: Speak fluently with different speeds;</div>
                <div >R: Achieve certain reading rate and efficiency;</div>
                <div >W: Write accurately, concisely and vividly;</div>
                </div>
                `,
                hsk63: `
                <div class="html_hsk23 ">
                <div >5000 commonly used words, master the</div>
                <div>rules of configuration, pronunciation and</div>
                <div>meaning; Achieve mastery with L/S/R/W.</div>
                </div>
                `,

                hsk51: `
                <div class="html_hsk11">
                <div >Use communicative strategies to </p>
                <div>express ideas coherently and clearly in </p>
                <div>professional field.</p>
                </div>
                `,
                hsk52: `
                <div class="html_hsk12">
                <div >L: Follow conversations in professional field;</div>
                <div>S: Clearly state opinions, reasons, perspectives;</div>
                <div >R: Read long and complex language materials;</div>
                <div >W: Describe, illustrate some specified topics;</div>
                </div>
                `,
                hsk53: `
                <div class="html_hsk23 html_hsk53">
                <div >12500 commonly used words, master the</div>
                <div>configuration rules of characters and</div>
                <div>words, use them correctly.</div>
                </div>

                `,

                hsk41: `
                <div class="html_hsk11">
                <div >Understand simple and familiar language</div>
                <div>materials in social situations, grasp the </div>
                <div>key points and details.</div>
                </div>
                `,
                hsk42: `
                <div class="html_hsk12 html_hsk42">
                <div >L: Follow speeches in social situations and work;</div>
                <div >S: Take part in general work and social topics;</div>
                <div >R: Read simple explanatory text of daily life;</div>
                <div >W: Write short paragraphs in a format;</div>
                </div>
                `,
                hsk43: `
                <div class="html_hsk23 html_hsk33">
                <div >1200 commonly used words, understand</div>
                <div>the structure of characters, words and </div>
                <div>sentences.</div>
                </div>
                `,

                hsk31: `
                <div class="html_hsk11">
                <div >Communicate with others on common</div>
                <div>life topics and organize simple speech</div>
                <div>segments.</div>
                </div>
                `,
                hsk32: `
                <div class="html_hsk12">
                <div >L: Follow simple conversations in daily life;</div>
                <div class="lt6">S: Basically express personal views in daily life;</div>
                <div >R: Read short materials accurately in study;</div>
                <div >W: Use simple words to describe information;</div>
                </div>
                `,
                hsk33: `
                <div class="html_hsk23">
                <div>600 commonly used words; Understand</div>
                <div>character composition and radicals,</div>
                <div>identify the sound, font and meaning.</div>
                </div>
                `,

                hsk21: `
                <div class="html_hsk11">
                <div>Communicate and introduce yourselve</div>
                <div>to others in a simple way on common </div>
                <div>topics of daily life.</div>
                </div>
                `,
                hsk22: `
                <div class="html_hsk22">
                <div>L: Follow basic corpus of study and daily life;</div>
                <div>S: Intonate accurately, imitate short sentence;</div>
                <div>R: Read basic characters, words, expressions;</div>
                <div>W: Master basic characters, stroke sequence;</div>
                </div>
                `,
                hsk23: `
                <div class="html_hsk13">
                <div>300 basic words related to daily life </div>
                <div>and school.</div>
                </div>
                `,

                hsk11: `
                <div class="html_hsk11">
                <div>Understand the most basic language</div>
                <div>materials, personal daily materials, and </div>
                <div>life-related materials.</div>
                </div>
                `,
                hsk12: `
                <div class="html_hsk12">
                <div>L: Understand basic classroom instruction;</div>
                <div>S: Follow and repeat the words and sentences;</div>
                <div>R: Master pinyin, simple characters and words;</div>
                <div>W: Imitate the basic characters in the lecture;</div>
                </div>
                `,
                hsk13: `
                <div class="html_hsk13">
                <div>150 basic words related to daily life </div>
                <div>and school.</div>
                </div>
                `,

                hskPE11: `
                <div class="html_en_ib11">
                Understand the most basic language</br>
                materials, personal daily materials, </br>
                and life-related materials.
                </div>
                `,
                hskPE12: `
                <div class="html_en_ib12">
                <div>L: Understand basic classroom instruction;</div>
                <div>S: Follow and repeat the words and sentences;</div>
                <div>R: Master pinyin, simple characters and words;</div>
                <div>W: Imitate the basic characters in the lecture;</div>
                </div>
                `,
                hskPE13: `
                <div class="html_en_ib13">
                150 basic words related to daily life</br>
                and school.
                </div>
                `,
                hskPE21: `
                <div class="html_en_ib11">
                Communicate and introduce yourselve<br/>
                to others in a simple way on common <br/>
                topics of daily life.
                </div>
                `,
                hskPE22: `
                <div class="html_en_ib121">
                <div>L: Follow basic corpus of study and daily life;</div>
                <div>S: Intonate accurately, imitate short sentence;</div>
                <div>R: Read basic characters, words, expressions;</div>
                <div>W: Master basic characters, stroke sequence;</div>
                </div>
                `,
                hskPE23: `
                <div class="html_en_ib13">
                300 basic words related to daily life </br>
                and school.
                </div>
                `,
                hskPE31: `
                <div class="html_en_ib11">
                Communicate with others on common<br/>
                life topics and organize simple speech<br/>
                segments.
                </div>
                `,
                hskPE32: `
                <div class="html_en_ib121">
                <div>L: Follow basic corpus of study and daily life;</div>
                <div>S: Basically express personal views in daily life;</div>
                <div>R: Read short materials accurately in study;</div>
                <div>W: Use simple words to describe information;</div>
                </div>
                `,
                hskPE33: `
                <div class="html_en_ib131">
                600 common words; Understand <br/>
                character composition and radicals, <br/>
                identify the sound, font and meaning.
                </div>
                `,
                hskPE41: `
                <div class="html_en_ib11">
                Understand simple and familiar <br/>
                language materials in social situations, <br/>
                grasp the key points and details.
                </div>
                `,
                hskPE42: `
                <div class="html_en_ib121">
                <div>L: Follow speeches in social topics and work;</div>
                <div>S: Take part in general work and social topics;</div>
                <div>R: Read simple explanatory text of daily life;</div>
                <div>W: Write short paragraphs in a format;</div>
                </div>
                `,
                hskPE43: `
                <div class="html_en_ib131">
                1200 common words, understand<br/>
                the structure of characters, words and<br/>
                sentences.
                </div>
                `,
                hskPE51: `
                <div class="html_en_ib11">
                Use communicative strategies to <br/>
                express ideas coherently and clearly in <br/>
                professional field.<br/>
                </div>
                `,
                hskPE52: `
                <div class="html_en_ib121">
                <div>L: Follow conversations in professional field;</div>
                <div>S: Clearly state opinion, reason, perspectives;</div>
                <div>R: Read long and complex language materials;</div>
                <div>W: Describe, illustrate some specified topics;</div>
                </div>
                `,
                hskPE53: `
                <div class="html_en_ib131">
                2500 common words, master the<br/>
                configuration rules of characters and <br/>
                words, use them correctly.<br/>
                </div>
                `,
                hskPE61: `
                <div class="html_en_ib612">
                Understand, summarize and analyze <br/>
                complex materials on various topics, <br/>
                participate in discuss. Master some<br/>
                idioms and colloquial sayings.
                </div>
                `,
                hskPE62: `
                <div class="html_en_ib121">
                <div>L: Follow complex topics in various fields;</div>
                <div>S: Speak fluently with different speeds;</div>
                <div>R: Achieve certain reading rate and efficiency;</div>
                <div>W: Write accurately, concisely and vividly;</div>
                </div>
                `,
                hskPE63: `
                <div class="html_en_ib131">
                5000 common words, master the rules <br/>
                of configuration, pronunciation and<br/>
                meaning; Achieve mastery with L/S/R/W.
                </div>
                `,
                qsn11: `
                <div class="html_qsn11">
                <div>Understand simple life-related corpus, </div>
                <div>can make a simple communication and </div>
                <div>self-introduction; Master pinyin; Read </div>
                <div>articles skillfully.</div>
                </div>
                `,
                qsn12: `
                <div class="html_qsn12">
                <div>L: Follow common materials and request in life;</div>
                <div>S: Master simple conversation and expression;</div>
                <div>R: Read accurately, fluently and with feelings;</div>
                <div>W: Memorize and master basic characters;</div>
                </div>
                `,
                qsn13: `
                <div class="html_qsn13">
                <div>Read 1600 and write 800 commonly used </p>
                <div>characters correctly, master pronunciation,</p>
                <div>shape and meaning . Understand the </p>
                <div>relationship between characters and words.</p>
                </div>
                `,
                qsnPE11: `
                <div class="html_en_ib11">
                Understand simple life-related corpus, </br>
                can make a simple communication and</br>
                self-introduction; Master pinyin; Read </br>
                articles skillfully.
                </div>
                `,
                qsnPE12: `
                <div class="html_en_ib12">
                <div>L: Follow common topics and request in life;</div>
                <div>S: Master simple conversation and expression;</div>
                <div>R: Get the thought and feeling of short articles;</div>
                <div>W: Memorize and master basic characters;</div>
                </div>
                `,
                qsnPE13: `
                <div class="html_en_ib13">
                Read 1600 and write 800 common </br>
                words correctly, master pronunciation,</br>
                shape and meaning . Understand the </br>
                relationship betw characters & words.
                </div>
                `,
                qsnPE21: `
                <div class="html_en_ib11">
                Understand topics related to life and</br>
                study; Organize simple paragraphs on</br>
                familiar topics; Masterexpression skills</br>
                such as stress, pause and intonation.
                </div>
                `,
                qsnPE22: `
                <div class="html_en_ib12">
                <div>L: Follow complex topics and request in life;</div>
                <div>S: Express views and needs with logic;</div>
                <div>R: Get the thought and feeling of short articles;</div>
                <div>W: Write with simple words and sentences;</div>
                </div>
                `,
                qsnPE23: `
                <div class="html_en_ib13">
                Read 2500 and write 1700 common</br>
                words correctly, master pronunciation, </br>
                shape and meaning. Understand the </br>
                components and radicals of characters.
                </div>
                `,
                qsnPE31: `
                <div class="html_en_ib11">
                Master topics in social situations, get </br>
                the key points; Communicate with </br>
                others coherently on familiar topics, </br>
                use basic communication strategies.
                </div>
                `,
                qsnPE32: `
                <div class="html_en_ib121">
                <div>L: Follow complex argumentative expressions;</div>
                <div>S: Express ideas coherently on general topics;</div>
                <div>R: Master reading skills, catch the key points;</div>
                <div>W: Accurate and appropriate in various styles;</div>
                </div>
                `,
                qsnPE33: `
                <div class="html_en_ib13">
                Read 3000 and write 2500 common</br>
                words correctly, master pronunciation,</br>
                shape and meaning. Understand the </br>
                internal law of Chinese characters.
                </div>
                `,

                qsn21: `
                <div class="html_qsn21">
                <div>Understand the materials related to life </div>
                <div>and study; Organize simple paragraphs on</div>
                <div>familiar topics; Master the expression skills</div>
                <div>such as stress, pause and intonation.</div>
                </div>
                `,
                qsn22: `
                <div class="html_qsn22">
                <div>L: Follow complex materials and request in life;</div>
                <div>S: Express personal views and needs with logic;</div>
                <div>R: Read the thought and feeling of short articles;</div>
                <div>W: Write with simple words and sentences;</div>
                </div>
                `,
                qsn23: `
                <div class="html_qsn23">
                <div>Read 2500 and write 1700 commonly used</div>
                <div>characters correctly, master pronunciation, </div>
                <div>shape and meaning. Understand the </div>
                <div>components and radicals of characters.</div>
                </div>
                `,

                qsn31: `
                <div class="html_qsn11">
                <div>Understand materials in social situations,</div>
                <div>catch the key points; Communicate with</div>
                <div>others coherently on familiar topics, use</div>
                <div>basic communication strategies.</div>
                </div>
                `,
                qsn32: `
                <div class="html_qsn12 html_qsn32">
                <div>L: Follow complex argumentative expressions;</div>
                <div>S: Express ideas coherently on general topics;</div>
                <div>R: Master reading skills, catch key informations;</div>
                <div>W: Accurate and appropriate in various styles;</div>
                </div>
                `,
                qsn33: `
                <div class="html_qsn13 ">
                    <div>Read 2500 and write 1700 commonly used</div>
                    <div>characters correctly, master pronunciation, </div>
                    <div>shape and meaning. Understand the internal</div>
                    <div>law of Chinese characters</div>
                </div>
                `,
            },
            Medium: {
                title1: "Teaching with Fir st-rate Faculty",
                text1: `
                <p style="margin-top:0.07rem;">Our teachers all come from the six major normal </P>
                <p>universities directly under the Ministry of Education,</P>
                <p>majoring in Chinese language and literature; More </P>
                <p style="margin-bottom:0.07rem;">than 80% have a master's degree or above in 211.</P>
                `,
                textPE1: `
                <p style="margin-top:0.22rem;">Our teachers all come from the six major</P>
                <p>normal universities directly under the</P>
                <p>Ministry of Education,majoring in Chinese </P>
                <p>language and literature; More than 80%</p>
                <p style="margin-bottom:0.22rem;">have a master's degree or above in 211.</P>
                `,
                text11: `
                <p>Cooperate with College of Arts of Southwest </P>
                <p>University and Huazhong Normal University, </P>
                <p>employ professors to customize the curriculum.</P>
                `,
                textPE11: `
                <p>Cooperate with College of Arts of Southwest</P>
                <p>University and Huazhong Normal University, </P>
                <p>employ professors to customize the </P>
                <p>curriculum.</p>
                `,

                title2: `"Fun and Inspiration" Principle`,
                text2: `
                <p style="margin-top:0.04rem;">Inspire students' divergent and active thinking </P>
                <p>by using an in-depth teaching style to teach </P>
                <p>difficult knowledge in a lively and interesting way. </P>
                <p>Help students to apply and integrate their </P>
                <p style="margin-bottom:0.03rem;">knowledge.</P>
                `,
                textPE2: `
                <p style="margin-top:0.2rem;">Inspire students' divergent and active</P>
                <p>thinking by using an in-depth teaching style</P>
                <p>to teach difficult knowledge in a lively and </P>
                <p>Hinteresting way. Help students to apply and </P>
                <p style="margin-bottom:0.2rem;">integrate their knowledge.</P>
                `,
                text21: `
                <p>Guide students to immerses in thematic cultural </P>
                <p>stories and narrative scenes, use language tools </P>
                <p>to deepen the understanding of cultural </P>
                <p>phenomena and concepts.</P>
                `,
                textPE21: `
                <p>Guide students to immerses in thematic </P>
                <p>cultural stories and narrative scenes, use </P>
                <p>language tools to deepen the understanding</P>
                <p>of cultural phenomena and concepts.</P>
                `,

                title3: `"Students-Oriented" Philosophy`,
                text3: `
                <p style="margin-top:0.05rem;">Design the questions from the perspective of </p>
                <p>students，guide them to understand the </p>
                <p style="margin-bottom:0.06rem;">knowledge with their own mind.</p>
                `,
                textPE3: `
                <p style="margin-top:0.2rem;">Design the questions from the perspective</p>
                <p>of students，guide them to understand the</p>
                <p style="margin-bottom:0.2rem;">knowledge with their own mind.</p>
                `,
                text31: `
                <p>With no rigid teaching, with no authority and </p>
                <p>absolute accuracy, we encourage students to </p>
                <p>learn and study in their own ways. We believe </p>
                <p>the knowledge increase step by step, only </p>
                <p>when they really accept it ,they can truly learn it.</p>
                `,
                textPE31: `
                <p>With no rigid teaching, with no authority and </p>
                <p>absolute accuracy, we encourage students </p>
                <p>to learn and study in their own ways. We </p>
                <p>believe the knowledge increase step by </p>
                <p>step, only when they really accept it ,they</p>
                <p>can truly learn it.</p>
                `,

                title4: `"Systematic and Personalized" Method`,
                text4: `
                <p style="margin-top:0.05rem;">Collect the common, high frequency and important </p>
                <p>knowledge to form a modular knowledge base, </p>
                <p>which is convenient for students with different </p>
                <p>foundations and goals to quickly fill in the </p>
                <p style="margin-bottom:0.03rem;">weakness and missing.</p>
                `,
                textPE4: `
                <p style="margin-top:0.2rem;">Collect the common, high frequency and  </p>
                <p>important knowledge to form a modular</p>
                <p>knowledge base, which is convenient for </p>
                <p>students with different foundations and goals</p>
                <p style="margin-bottom:0.2rem;">to quickly fill in the weakness and missing.</p>
                `,
                text41: `
                <p>The class could be both personalized and </p>
                <p>systematic by flexible using of the knowledge </p>
                <p>module base, improve students' potential scores </p>
                <p>and learning efficiency.</p>
                `,
                textPE41: `
                <p>The class could be both personalized and  </p>
                <p>systematic by flexible using of the knowledge </p>
                <p>module base, improve students' potential </p>
                <p>scores and learning efficiency.</p>
                `,

                title5: `Combines Learning with  Examination`,
                text5: `
                <p style="margin-top:0.03rem;">The teaching system combines both basic Chinese</p>
                <p>teaching and selection examination, forming a </p>
                <p>scientific knowledge framework and Chinese </p>
                <p>advanced map. And the curriculum is developed </p>
                <p style="margin-bottom:0.05rem;">on this system to form a knowledge module library.</p>
                `,
                textPE5: `
                <p style="margin-top:0.15rem;">The teaching system combines both basic</p>
                <p>Chinese teaching and selection examination, </p>
                <p>forming a scientific knowledge framework </p>
                <p>and Chinese advanced map. And the </p>
                <p>curriculum is  developed on this system to</p>
                <p style="margin-bottom:0.15rem;">form a knowledge module library.</p>
                `,
                text51: `
                <p>Comprehensively improve listening, speaking, </p>
                <p>reading and writing abilities , taking examination </p>
                <p>syllabus as the goal, using knowledge module </p>
                <p>system as the auxiliary.</p>
                `,
                textPE51: `
                <p>Comprehensively improve listening, speaking, </p>
                <p>reading and writing abilities , taking  </p>
                <p>examination syllabus as the goal, using  </p>
                <p>knowledge module system as the auxiliary.</p>
                `,
            },
            home: {
                JoinT1: "Join ZhiXing for a Free Trial",
                JoinT2: "Free trial with absolute zero cost",
                JoinT3: "Convenient registration for course details",
                JoinT4: "Book a Free Trial Now",
                JoinT4PE: "Book a Free Trial",
                minTit1: "Course Syllabus",
                minTit2: "ZhiXing Character",
                minTit3: "Customer Evaluation ",
                minTit31: "Customer Feedback ",
                minTit4: "Teachers",
                minTit5: "Concept of ZhiXing",
                minTit6: "Q&A",
                minTit61: "General issues",
                minTit7: "Course Syllabus",
                minTit8: "Course Content",
                minTit9: "Course Characteristics",
                minTit10: "ZhiXing Advantage",
                minTit11: "Diversified Development",
                minTit12: "Supporting Growth",
                minTit13: "Teaching and Research",
                minTit14: "ZhiXing Commitment",
                courseType1: "Juvenile Course",
                courseTypePE11: "AP",
                courseTypePE12: "AP Course",
                courseTypePE21: "HSK",
                courseTypePE22: "HSK Course",
                courseTypePE31: "Juvenile",
                courseTypePE32: "Juvenile Course",
                courseType2: "HSK Course",
                courseType3: "AP Course",
                courseType4: "IB Course",
                courseTypePE41: "IB",
                courseTypePE42: "IB Course",
                courseType5: "O-LEVEL Course",
                courseTypePE51: "O-Level",
                courseTypePE52: "O-Level Course",
                courseTh1: "Course Grade",
                courseTh2: "Integrated Target",
                courseTh3: "L/S/R/W",
                courseThPE2: `T<br/>A<br/>R<br/>G<br/>E<br/>T`,
                courseThPE3: `L<br/>W<br/>R<br/>S`,
                courseThPE4: `C<br/>H<br/>A<br/>R`,
                courseTh4: "Characters and Words",
                courseTh5: "Learning Theme",
            },
            header: {
                li1: "Chinese Course",
                li2: "Chinese Exam",
                li3: "Advantage",
                li4: "Faculty",
                li5: "Blog",
                li6: "Free Trail",
                li7: "Juvenile Course",
                li8: "HSK Standard Course",
                button: "Study Center",
            },
            isLang: "2",
        },
    },
});

export default i18n;
